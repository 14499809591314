

export function SellCompletedForm() {
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <label className="block mb-2 pt-3 text-xl text-center font-semibold text-green-300">
          Congrats on Listing your Artwork on Monet!
        </label>
        <label className="pl-3 pt-4 text-center block mb-2 text-md font-medium text-blue-300">
          Expect an email on the status of your listing in 2-3 business days
        </label>
      </div> 
    </>
  )
}