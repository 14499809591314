import { NextPage } from "next";
import React, { useState } from "react";
import Image from "next/image";
import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL } from "firebase/storage";
import { auth, database, updateData } from "../../firebase/firebaseApp";
import { updateProfile } from "firebase/auth";
import { set, ref as refDB} from "firebase/database";

interface Props {
  dirs: string[];
  user: {
    uid: string;
    photoURL: string;
  };
}

const UploadImage: NextPage<Props> = ({user}) => {
  const [uploading, setUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [error, setError] = useState('');
  const [pageNext, setPageNext] = useState(false);

  const handleUpload = async (file: File) => {
    if(file === undefined){
      setError("No Photo was Uploaded");
      return null;
    }
    if (file.type.split("/")[0] !== "image"){
      setError("Photo must be in the correct format");
      return null;
    }
    setUploading(true);
    setSelectedImage(URL.createObjectURL(file));
    try {
      if (!file) return;
      // Make sure the file is smaller than 10MB
      if (file.size > 10000000) {
        setError("Photo quality is too high");
        setSelectedImage("");
        return;
      }
      // upload Image to database to match user-uid
      const storage = getStorage();
      const profilePicRef = ref(storage, `profilePic/${user.uid}`);
      setError("");

      // 'file' comes from the Blob or File API
      // const nameimage = "DVGPL16SOPTvE2xXMf6Ta3DVIb83";
      // const desertRef = ref(storage, `profilePic/${nameimage}`);
      // deleteObject(desertRef).then(() => {
      //   // File deleted successfully
      // }).catch((error) => {
      //   // Uh-oh, an error occurred!
      //   console.log(error)
      // });
      // ------------
      await uploadBytes(profilePicRef, file).then((snapshot) => {
        console.log('Uploaded a blob or file!');
      });
      await getDownloadURL(ref(storage, `profilePic/${user.uid}`)).then((url) => {
        updateProfile(auth.currentUser!, {photoURL: url});
        const newShare = refDB(database, `/user_profile/${user.uid}/profileURL`);
        set(newShare,  url);
        const updates: Record<string, any> = {};
        updates[`user_profile/${user.uid}/profileURL`] = url;
        updateData(updates);
      });
      setPageNext(true)
    } catch (error: any) {
      console.log(error.response?.data);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center max-w-4xl mx-auto pb-10 pt-5 space-y-6">
      <div className="text-white font-base text-3xl">{uploading ? "" : "Upload Profile Photo"}</div>
      <label>
        <input
          type="file"
          hidden
          onChange={({ target }) => {
            if (target.files) {
              handleUpload(target.files[0]);
            }
          }}
        />
        <div className="w-40 h-40 aspect-video rounded-full flex items-center justify-center border-2 border-dashed cursor-pointer">
          <div>
            <div className="z-10 absolute cursor-pointer mt-24 ml-28 flex items-center justify-center bg-red-600 w-10 h-10 rounded-full text-center text-5xl text-white">+</div>
              <div className="relative w-40 h-40">
                <Image
                  unoptimized
                  sizes="(max-width: 768px) 100vw,
                    (max-width: 1200px) 50vw,
                    33vw"
                  src={selectedImage ? selectedImage : user.photoURL ? user.photoURL : "/blank-profile-picture.webp" }
                  alt="Monet Logo"
                  fill
                  className="object-cover rounded-full"
                />
              </div>
            </div>
        </div>
      </label>
      <div className="text-red-600 text-lg">
        {error}
      </div>
    </div>
  );
};

export default UploadImage;