import React from 'react';
import Image from 'next/image';

const Author = ({ author }) => {
  var srcFound = "";
  if(author.username === "julianowahab"){
    srcFound = "/juliano.webp";
  }
  return (
    <div className="mt-10 flex items-start justify-center mb-8 rounded-lg bg-black bg-opacity-20">
      <div className="relative h-20 w-20 flex items-start justify-start">
        <Image
          unoptimized
          alt={author.username}
          fill
          className="align-middle rounded-full"
          src={srcFound}
        />
      </div>
      <div className="flex flex-col w-2/3 ml-7">
        <div className="text-white mt-4 mb-4 text-xl font-bold">{author.username}</div>
        <div className="text-white mx-3 text-lg">{author.userBio}</div>
      </div>
    </div>
  );
}

export default Author;
