import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import { getComments } from '../services';
import TimeConverter from './TimeConverter';

const Comments = ({ slug }) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    getComments(slug).then((result) => {
      setComments(result);
    });
  }, []);

  return (
    <>
      {comments.length > 0 && (
        <div className="bg-gray-900 shadow-lg rounded-lg p-8 pb-12 mb-8">
          <h3 className="text-xl text-blue-200 mb-8 font-semibold border-b pb-4">
            {comments.length}
            {' '}
            {comments.length < 2 ? "Comment" : "Comments"}
          </h3>
            {comments.map((comment, index) => (
              <div key={index} className=" mb-4 pb-4">
                <div className="mb-4 flex flex-row items-center justify-centerf ">
                  <div className="font-semibold text-blue-100 text-medium pr-2">{comment.name}</div>
                  {' '}
                  <TimeConverter date={comment.createdAt} />
                </div>
                <div className="whitespace-pre-line text-blue-100 w-full">{parse(comment.comment)}</div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default Comments;
