import React from 'react';
import Image from 'next/image';
import moment from 'moment';
import Link from 'next/link';


const PostCard = ({ arr }) => {
  var srcFound = "";
  if(arr){
    if(arr.username === "julianowahab"){
      srcFound = "/juliano.webp";
    }
    return (
      <div className="bg-black shadow-lg rounded-lg mt-7">
        <Link prefetch={false} href={`/blog/${arr.slug}`}>
        <div className="relative h-56 md:h-full w-full overflow-hidden shadow-md pb-40 md:pb-80 mb-3 md:mb-6">
          <Image
            fill
            sizes="(max-width: 768px) 100vw,
                (max-width: 1200px) 50vw,
                33vw"
            src={arr.postImageURL} 
            alt={arr.postAltImage} 
            className="object-center absolute object-cover shadow-lg border-b border-blue-500 rounded-t-lg " 
          />
        </div>
        <h1 className="transition mx-3 md:mx-1 duration-700 text-center mb-4 md:mb-8 cursor-pointer text-blue-200 hover:text-blue-400 text-lg md:text-3xl md:mx-3 font-semibold">
          {arr.title}
        </h1>
        <div className="block lg:flex text-center items-center justify-center w-full mb-3">
          <div className="flex items-center justify-center mb-4 mx:3 lg:mb-0 w-full lg:w-auto mr-8 items-center">
            <div className="relative h-10 w-10">
              <Image
                alt={arr.username}
                fill
                sizes="40px"
                className="align-middle object-cover rounded-full"
                src={srcFound}
              />
            </div>
            <div className="inline align-middle text-gray-200 ml-2 font-medium text-sm">{"@" + arr.username}</div>
            <div className="pl-10 font-medium text-gray-400">
              <span className="align-middle text-xs">{moment(arr.createdAt).format('MMM DD, YYYY')}</span>
            </div>
          </div>
        </div>
        <div className="text-lg text-gray-500 font-normal md:pt-5 pb-3 pl-10">Reads: {arr.reads}</div>
        </Link>
      </div>
    );
  }
}

export default PostCard;
