import axios from "axios";
import { useState } from "react";


type BankData = {
  financialInstitutionName: string, //theres a list of all acceptable banks in the world, in drive
  BankCountry: string,

  // US Bank
    NineDigitRoutingNumber: string,
  // UK Bank
    bankSortCode: string,
    federalTaxID: string,
    stateTaxID: string,
  // Canada Bank
    accountTypeCanada: string, //savings or checking
    institutionNumber: string,
    transitNumber: string,
  // Australia, New Zealand Bank
    BSB: string,
  // China Bank 
    isBusinessBankAccount: boolean, 
    // ********* start ********
    //********* if isBusinessBankAccount === false ************
    nationalIDNumber: string,
    //******** if isBusinessBankAccount === true ************
    businessLicenseNumber: string,
    // ********* end ********
    bankName: string,
    branchName: string,
    bankCity: string,
    bankProvince: string,
    accountHolderName: string,
    isBankAccountNumber: boolean, 
    // if isBankAccountNumber === false its a debit card number
    debitCardNumber: string,
    // 
  // Hong Kong, Singapore Bank
    clearingCode: string, // also called bankCode
    branchCode: string,
  // India Bank
    accountTypeIndia: string, //savings, overdraft, or current 
    IFSC: string,
  // Morocco Bank
    BICMorocco: string, //also called BIC
  // Philippines Bank
    BRSTN: string,


  // every country above needs a Bank account number, except the ones below
    bankAccountNumber: string,
  // Belgium, Bulgaria, Croatia, Cyprus, Czech Rep, Denmark, Estonia, Finland, France
  // Germany, Gibraltar, Greece, Hungary, Ireland, Italy, Latvia, Liechtenstein,
  // Lithuania, Luxembourg, Malta, Monaco, Netherlands, Norway, Poland, Portugal , Romania,
  // San Marino, Slovakia, Slovenia, Spain, Switzerland,Bank
    BIC: string,
    IBAN: string,
  // everyone else goes through hyperwallet

  // I need credit card statements or bank statements
  statements: any,
  IDPass: any,
  facePhoto: any,

  // error handling
  error: string,
}


type BankFormProps = BankData & {
  updateFields: (fields: Partial<BankData>) => void
}


export function BankForm({NineDigitRoutingNumber, error, BankCountry, financialInstitutionName, updateFields}: BankFormProps) {

//   const getShuftiProAccessToken = async () => {
//     async function generateAccessToken() {
//       let payload: {
//         reference: string,
//         callback_url: string,
//         email: string,
//         country: string,
//         language: string,
//         verification_mode: string,
//         face: {
//           proof: string
//         }
//       } = {
//         reference: `SP_REQUEST_${Math.random()}`,
//         callback_url: "https://monet.io/profile/sp-notify-callback",
//         email: "johndoe@example.com",
//         country: "GB",
//         language: "EN",
//         verification_mode: "any",
//         face: {
//           proof: ""
//         }
//       };   
//       const toBase64 = (text: string): string => {
//         return Buffer.from(text, 'binary').toString('base64');
//       };
    
//       var token = toBase64(`${process.env.NEXT_PUBLIC_SHUFTIPRO_CLIENT_ID}:${process.env.NEXT_PUBLIC_SHUFTIPRO_SECRET_KEY}`);
      
//       const responseToken = await axios.post(
//         'https://api.shuftipro.com/',
//         {
//           headers : {
//             'Accept'        : 'application/json',
//             'Content-Type'  : 'application/json',
//             'Authorization' : 'Basic ' +token
//           },
//         }, payload);
//       return responseToken.data;
//     } 
//   const toBase64 = (text: string): string => {
//     return Buffer.from(text, 'binary').toString('base64');
//   };

//   var token = toBase64(`${process.env.NEXT_PUBLIC_SHUFTIPRO_CLIENT_ID}:${process.env.NEXT_PUBLIC_SHUFTIPRO_SECRET_KEY}`);
//   const response = await fetch('https://api.shuftipro.com/', {  method : 'post',
//     headers : {
//       'Accept'        : 'application/json',
//       'Content-Type'  : 'application/json',
//       'Authorization' : 'Basic ' +token
//     },
//     body: JSON.stringify(payload)}).then(function(response) {
//       console.log("1234:", response)

//        return response.json();
//   }).then(function(data) { return data; });

//   console.log("resppppoo", response)
  
//   // var token = "YOUR_ACCESS_TOKEN";
// //   const resData = await fetch('https://api.shuftipro.com/', {  method : 'post',
// //   headers : {
// //     'Accept'        : 'application/json',
// //     'Content-Type'  : 'application/json',
// //     'Authorization' : 'Bearer ' +token
// //   },
// //   body: JSON.stringify(payload)}).then(function(response) {
// //      return response.json();
// // }).then(function(data) { return data; });

// // console.log("res46644ppo", resData)
//   }

  const handleUpload = async (file: File, type: string) => {
    if(file === undefined){
      // error = "No Photo was Uploaded";
      updateFields({error: "No Photo was Uploaded"});
      console.log("error1", error);
      return null;
    }
    if (file.type.split("/")[0] !== "image"){
      error = "Photo must be in the correct format";
      updateFields({error: "Photo must be in the correct format"});
      console.log("error2", error);
      return null;
    }
    if(type === "statements") updateFields({error: "", statements: file});
    if(type === "IDPass") updateFields({error: "", IDPass: file});
    if(type === "facePhoto") updateFields({error: "", facePhoto: file});
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col pb-5">
          <label className="block mb-2 text-lg font-medium text-green-300">
            Bank name
          </label>
          <input autoFocus onChange={e => updateFields({financialInstitutionName: e.target.value})} value={financialInstitutionName} type="text" name="BankCountry" id="BankCountry" className="bg-black focus:outline-none border-b border-blue-300
            text-blue-100 text-sm block w-full p-2.5 placeholder:text-xs"
            placeholder="Ex: Bank of America" required
             />
        </div> 
        <div className="flex flex-col pb-5">
          <label className="block mb-2 text-lg font-medium text-green-300">
            Country of Bank
          </label>
          <input autoFocus onChange={e => updateFields({BankCountry: e.target.value})} value={BankCountry} type="text" name="BankCountry" id="BankCountry" className="bg-black focus:outline-none border-b border-blue-300
            text-blue-100 text-sm block w-full p-2.5 placeholder:text-xs"
            placeholder="Ex: United States of America" required
             />
        </div> 
        {/********* UNITED STATES OF AMERICA **********/}
        {(BankCountry.replace(/ /g,"").toLowerCase() === "unitedstatesofamerica" || BankCountry.replace(/ /g,"").toLowerCase() === "unitedstates") &&
          <div className="flex flex-col pb-5">
            <label className="block mb-2 text-lg font-medium text-green-300">
              9-Digit Rounting Number
            </label>
            <input autoFocus onChange={e => updateFields({NineDigitRoutingNumber: NineDigitRoutingNumber})} value={NineDigitRoutingNumber} type="string" name="BankCountry" id="BankCountry" className="bg-black focus:outline-none border-b border-blue-300
              text-blue-100 text-sm block w-full p-2.5 placeholder:text-xs"
              placeholder="Ex: United States of America"
              required />
          </div> 
        }
        <label className="block mb-2 text-lg font-medium text-green-300">
          Credit card or Bank statements
        </label>
        <input 
          type="file" 
          onChange={({ target }) => {
            if (target.files) {
              handleUpload(target.files[0], "statements");
            }
          }} 
          name="statements" id="statements" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          placeholder="First Name" required
        />
        <label className="block mb-2 text-lg font-medium text-green-300">
          Picture of your ID/Passport
        </label>
        <input type="file"
          onChange={({ target }) => {
            if (target.files) {
              handleUpload(target.files[0], "IDPass");
            }
          }} 
          name="IDPass" id="IDPass" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          placeholder="Middle name(s)" required
        />
        <label className="block mb-2 text-lg font-medium text-green-300">
          Take a clear picture of your face
        </label>
        <input type="file" 
          onChange={({ target }) => {
            if (target.files) {
              handleUpload(target.files[0], "facePhoto");
            }
          }} 
          name="facePhoto" id="facePhoto" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          placeholder="Last Name" required
        />
      </div> 
    </>
  )
}