import { FormEvent, useState } from "react";
import { sellUseMultiStepForm } from "./sellFormHelp";
import { ArtForm } from "./SellForm";
import { SellCompletedForm } from "./SellCompletedForm";
import { sendContactForm } from "../../../lib/api";
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import { updateData } from "../../../firebase/firebaseApp";
import { v4 as uuidv4 } from 'uuid';
import { PhotoForm } from "./SellPhotoForm";
import Loader from "../../Loader";

type FormData = {
  artistName: string,
  Title: string,
  Medium: string,
  year: string,
  height: string,
  width: string,
  depth: string,
  in_cm: boolean,
  provenance: string,
  artworkAddress: string,
  AdditionalInfo: string,
  photo1: any,
  photo2: any,
  photo3: any,
  errorform: string,
  createdAt: any,
  listingsID: string,
  verified: boolean,
  UserUid: string,
  username: string,
  profileURL: string,
  reservePrice: string,
  hasFrame: boolean,
  rarity: string,
  tags: Array<string>,
  acceptOffers: boolean,
  description: string,
}

const initial_data: FormData = {
  artistName: "",
  Title: "",
  Medium: "",
  year: "",
  height: "",
  width: "",
  depth: "",
  in_cm: false,
  provenance: "",
  artworkAddress: "",
  AdditionalInfo: "",
  photo1: undefined,
  photo2: undefined,
  photo3: undefined,
  errorform: "",
  createdAt: new Date(),
  listingsID: "",
  verified: false,
  UserUid: "",
  username: "",
  profileURL: "",
  reservePrice: "",
  hasFrame: false,
  rarity: "",
  tags: [],
  acceptOffers: false,
  description: "",
}

function SellForm(user: any) {
  const [data, setData] = useState(initial_data);
  const [error, setError] = useState('');
  const storage = getStorage();
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  function updateFields(fields: Partial<FormData>){
    setData(prev => {
      return {...prev, ...fields}
    })
  }
  const {steps, curIndex, step, isFirstStep, backForm, nextForm, isLastStep} = sellUseMultiStepForm(
    [<ArtForm {...data} updateFields={updateFields}/>, <PhotoForm  {...data} updateFields={updateFields}/>, <SellCompletedForm />
  ]);
  if(curIndex >= 1 || isLoading){
    window.scrollTo({
      top: 10,
      behavior: "smooth"
    })
  }
  // it's not updating the fields/ deleting the entries with wrong values.
  async function onSubmit(e: FormEvent){
    e.preventDefault();
    const regex = /^\d+(\.\d+)?$/;
    console.log("buttttt", data)
    try {
      setIsLoading(true); 
    if(data.Medium === "Select" || data.Medium === ""){
      setError("Please select a medium");
      setData(prev => {
        prev.Medium= "";
        return {...prev}
      })
      return
    } if(Number(data.year) > new Date().getFullYear()){
      setError("Year cannot be in the future");
      setData(prev => {
        prev.year= "";
        return {...prev}
      })
      return
    } if(!/^\d+$/.test(data.year)){
      setError("Year is not a number");
      setData(prev => {
        prev.year= "";
        return {...prev}
      })
      return
    } if(!/^\d+$/.test(data.height)){
      setError("Height is not a number");
      setData(prev => {
        prev.height= "";
        return {...prev}
      })
      return
    } if(!/^\d+$/.test(data.width)){
      setError("Width is not a number");
      setData(prev => {
        prev.width= "";
        return {...prev}
      })
      return
    } if(!/^\d+$/.test(data.depth) && data.depth !== ""){
      setError("Depth is not a number");
      setData(prev => {
        prev.depth= "";
        return {...prev}
      })
      return
    } if(curIndex === 0 && !isLastStep){
      setError("");
      return nextForm();
    } if(data.errorform !== ""){
      setError(data.errorform);
      return;
    } if(!regex.test(data.reservePrice)){
      setError("Reserve Price is not a number or decimal, Ex: 100 or 100.00");
      return;
    }
    else if(data.photo1 && data.photo2){
      if (data.photo1.size > 10000000 && data.photo2.size > 10000000 && data.photo3.size > 10000000) {
        setError("Photo is too large, max size is 10MB");
        return "failed";
      }
      const listingsID = data.artistName.replace(/\s+/g, '-') + "-" + data.Title.replace(/\s+/g, '-') + "-" + uuidv4();
      data.listingsID = listingsID;
      const photo1ref = ref(storage, `userPosts/${user.user.uid}/artListings/${listingsID}/photo1`);
      const photo2ref = ref(storage, `userPosts/${user.user.uid}/artListings/${listingsID}/photo2`);
      const photo3ref = ref(storage, `userPosts/${user.user.uid}/artListings/${listingsID}/photo3`);  
      // upload Image to Firebase Storage db to match user-uid
      await uploadBytes(photo1ref, data.photo1).then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot, data.photo1);
      });
      await uploadBytes(photo2ref, data.photo2).then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot, data.photo2);
      });
      if(data.photo3){
        await uploadBytes(photo3ref, data.photo3).then((snapshot) => {
          console.log('Uploaded a blob or file!', snapshot, data.photo3);
        });
        await getDownloadURL(photo3ref).then((url) => {
          data.photo3 = url;
        });
      }else{
        data.photo3 = "";
      }
      await getDownloadURL(photo1ref).then((url) => {
        data.photo1 = url;
        console.log("getiing URL", data.photo1)
      });
      await getDownloadURL(photo2ref).then((url) => {
        data.photo2 = url;
        console.log("getiing URL22", data.photo2)
      });
      data.UserUid = user.user.uid;
      const updates: { [key: string]: object } = {};
      updates[`/user_profile/${user.user.uid}/artListings/${listingsID}/`] = data;
      updates[`/artListings/${listingsID}/`] = data;
      // Add data to firebase RealTime Database
      console.log(updates)
      updateData(updates);

      const message= `${user.user.displayName}'s ${data.Medium} by ${data.artistName},
       Artwork Title: ${data.Title}, Artwork Address: ${data.artworkAddress},
       Provenance: ${data.provenance}, Additional Info: ${data.AdditionalInfo}
       is selling. Make sure to verify the work & reply on its status before 24h`;
      //  send Email that email form is received or toast.
      const newVal =  { uid: user.user.uid, name: user.user.displayName, email: user.user.email, subject: "Art Listing", message: message};
      await sendContactForm(newVal);
      setError("");
      return nextForm();
    }
    else if(!isLastStep) {
      setError("");
      return nextForm();
    }}catch (error) {
      // Handle errors here
      setError("An error occurred");
    } finally {
      setIsLoading(false); // Set loading state to false after operations complete
    }
  }

  return (
    <div className="w-full bg-black">
      {isLoading && <div className="my-20">
        <div className="text-sm text-center py-5 text-gray-300">One moment, while we process your information</div>
        <Loader />
        </div>}
      {!isLoading &&  <form onSubmit={onSubmit} className="m-10 p-10">
        <div className="flex items-center justify-end text-white">
          {curIndex + 1} / {steps.length}
        </div>
        {isLastStep ? null : <div className="flex pb-5 items-start underline text-white justify-start">
          {!isFirstStep && <button type="submit" onClick={backForm}>Back</button>}
        </div>}
        {step}
        <div className="pt-10">
          <div className="text-red-400 text-lg">
            {error}
          </div>
          <div className="flex items-center justify-center">
          {isLastStep ? null : 
            <div className="flex items-center justify-center">
            <button type="submit" className="mt-5 mx-3 w-full text-white bg-blue-600 hover:font-semibold
            focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              {isLastStep ? "Finish" : "Continue to last step"}
            </button>
          </div>}
          </div>
        </div>
      </form>}
    </div>
  );
}

export default SellForm;