import React, { useEffect } from 'react';
import '../styles/globals.scss';
import { Layout } from '../components';
import Head from 'next/head';
import initAuth from '../firebase/initAuth'; // the module you created above
import { analytics } from '../firebase/firebaseApp';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

initAuth();

function MyApp({ Component, pageProps }) {

  useEffect(() => {
    analytics;
  }, []);
  
  return (
    <>
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
        <meta name="description" content="With Monet, you can create virtual spaces in minutes. Scan your objects into 3D and add them to your space and deploy it everywhere"/>
        <meta name="viewport" content="width=device-width,minimum-scale=1, initial-scale=1"/>
      </Head>
        <PayPalScriptProvider deferLoading={true}>
          <Layout>
            <Component {...pageProps}/>
          </Layout>
        </PayPalScriptProvider>
    </>
  );
}

export default MyApp;
