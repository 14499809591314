

type UserData = {
  firstName: string,
  middleName: string,
  lastName: string,
}


type UserFormProps = UserData & {
  updateFields: (fields: Partial<UserData>) => void
}


export function UserForm({firstName, lastName, middleName, updateFields}: UserFormProps) {
  return (
    <>
      <div className="flex flex-col">
        <div className="text-semibold text-lg">Let's get to know you</div>
        <label className="text-xs py-5 font-medium text-blue-300">Enter your complete name, as it appears on the passport or ID</label>
        <label className="block mb-2 text-lg font-medium text-green-300">
          First Name
        </label>
        <input onChange={e => updateFields({firstName: e.target.value})} value={firstName} type="text" name="firstname" id="firstname" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          placeholder="First Name" required
        />
        <label className="block mb-2 text-lg font-medium text-green-300">
          Middle name(s)
        </label>
        <input onChange={e => updateFields({middleName: e.target.value})} value={middleName} type="text" name="middlename" id="middlename" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          placeholder="Middle name(s)"
        />
        <label className="block mb-2 text-lg font-medium text-green-300">
          Last name
        </label>
        <input onChange={e => updateFields({lastName: e.target.value})} value={lastName} type="text" name="lastname" id="lastname" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          placeholder="Last Name" required
        />
      </div> 
    </>
  )
}