import React from 'react';
import moment from 'moment';
import Image from 'next/image';
import { auth } from '../firebase/firebaseApp';
import Head from 'next/head';
import { RichText } from '@graphcms/rich-text-react-renderer';
import Share from './Share';

const PostDetail = ({ post }) => {
  const user = auth.currentUser;
  return (
    <> 
      <Head>
        <title>{post.title}</title>
        <meta name="description" content={post.excerpt} key="desc" />
        <meta property="og:title" content={post.title} />
        <meta
          property="og:description"
          content={post.excerpt}
        />
        <meta
          property="og:image"
          content={post.postImageURL}
        />
      </Head>
      <div className="bg-white shadow-lg rounded-lg pb-12 mb-8">
        <div className="relative h-96 md:h-[32rem] lg:h-[36rem] w-full object-cover overflow-hidden shadow-md mb-6">
          <Image 
            unoptimized
            fill
            priority
            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
            src={post.postImageURL} 
            alt={post.postAltImage} 
            className="object-center object-cover shadow-lg rounded-t-lg" 
          />
        </div>
        <div className="sm:px-4 mx-4 lg:px-0">
          <div className="flex flex-row justify-center items-center">
            <div className="mb-10 w-8/12">
              <div className="flex sm:mr-8 items-center">
                <div className="relative h-14 w-14">
                  <Image
                    unoptimized
                    alt={post.username}
                    priority
                    fill
                    className="align-middle object-cover rounded-full h-10 w-10 sm:h-14 sm:w-14"
                    src={post.profileURL}
                    sizes="(max-width: 768px) 100vw,
                (max-width: 1200px) 50vw,
                33vw"
                  />
                </div>
                <div className="inline text-gray-700 ml-2 font-medium text-lg">{post.username}</div>
              </div>
            </div>
            <div className="mb-10 flex w-4/12 items-center justify-end font-normal text-sm text-gray-500">
              <span className="align-middle">{moment(post.createdAt).format('MMM DD, YYYY')}</span>
            </div>
          </div>
          <h1 className="mb-14 text-center text-3xl font-semibold">{post.title}</h1>
          <RichText
            content={JSON.parse(post.postText)}
            renderers={{
              h1: ({ children }) => <h1 className="text-white mx-2">{children}</h1>,
              h3: ({children}) => <h3 className="text-3xl font-semibold mx-7 mt-20 mb-10">{children}</h3>,
              h4: ({children}) => <h4 className="text-2xl text-center font-semibold mt-20 mb-10">{children}</h4>,     
              p: ({children}) => <div className="mb-5 mx-7 text-lg">{children}</div>,
              ol: ({children}) => <div className="mb-8 mx-7 text-lg">{children}</div>,
              blockquote: ({children}) => <div className="text-xs text-center font-base mb-7">{children}</div>,
              bold: ({ children }) => <div className="inline block py-4 font-bold">{children}</div>,
              a: ({href, children}) => { 
                return (<a target="_blank" className="text-blue-600 font-semibold" href={href}>{children}</a>)
              },
              img: ({src, width, height, title}) => 
                <div className="flex mt-14 items-center justify-center">
                  <Image unoptimized width={width} height={height} className="mb-3" src={src} alt={title}/>
                </div>,
                code: ({ children }) => {
                  const html = children;
                  return (
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  );
                }
            }}
          /> 
        </div>
        <Share isBlog={true} url={"monet.io/blog/"} post={post} uid={user?.uid} />
      </div> 
    </>
  );
};

export default PostDetail;
