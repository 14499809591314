import React, { useState, useEffect } from 'react';
import { getCategories } from '../../services';
import Link from 'next/link';

const ExpHeader = ({color}) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories().then((newCategories) => {
      setCategories(newCategories);
    });
  }, []);
  
  console.log("categories----", categories)
  return (
    <div className="relative pb-20 sm:pb-0">
      <div className={`top-0 left-0 right-0 z-30 fixed sm:w-full bg-black flex sm:justific-evenly sm:items-center sm:pl-10 pt-10 sm:pt-11 border-b ${color}`}>
        <div className="overflow-x-scroll sm:px-2 py-2 pt-2 md:pt-5">
          <div className="flex flex-row  pt-3 md:float-right md:contents">
            {categories.map((category, index) => (
              <Link prefetch={false} key={index} href={`/category/${category.slug}`}><span className="md:float-left mt-2 sm:align-middle text-white ml-4 pr-5 font-semibold cursor-pointer">{category.name}</span></Link>
            ))}
              {/* <Link prefetch={false} key={6} href={`/category/magazine`}><span className="md:float-left mt-2 sm:align-middle text-green-400 ml-4 pr-5 font-semibold cursor-pointer">Magazine</span></Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpHeader;
