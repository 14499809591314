import { ReactElement, useState } from "react"


export function useMultiStepForm(steps: ReactElement[]){
  const [curIndex, setCurIndex] = useState(2);
  const [error, setError] = useState('');

  function ErrorMessage(errorMess: string){
    setError(errorMess)
  }
  function nextForm(){
    setCurIndex((i) => {
      if(i >= steps.length -1) return i
      return i+1
    })
  }
  function backForm(){
    setCurIndex((i) => {
      if(i <= 0) return i
      return i-1
    })
  }
  function goToForm(index: number){
    setCurIndex(index)
  }
  return {
    curIndex,
    isFirstStep: curIndex === 0,
    isLastStep: curIndex === steps.length -1,
    step: steps[curIndex],
    goToForm, nextForm, backForm,
    steps,
    ErrorMessage,
    error,
  }
}
