'use client';

import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import PostCard from './Blog/PostCard';
import TimeConverter from './TimeConverter';
import Link from 'next/link';
import { AnimatePresence, motion } from 'framer-motion';
import Modal from './Modal/index';
import { database, updateData } from '../firebase/firebaseApp';
import {
  useUser,
  withUser,
} from 'next-firebase-auth';
import { increment, remove, ref, onValue } from 'firebase/database';
import { toast } from 'react-toastify';
import { Listings } from '../components';

export const dynamic = 'auto',
dynamicParams = true, revalidate = 0, fetchCache = 'auto', runtime= 'nodejs', preferredRegion = 'auto'


const Update = ({arr}) => {
  var srcFound = "";
  var bgColor = "bg-black";
  const [liked, setLiked] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const close = () => setOptionsOpen(false);
  const open = () => setOptionsOpen(true);
  const user = useUser();

  var hrefAuthor = "https://www.monet.io/Profile/LLfX2KcUvcZDFSepDRHnb81PU1u2";
  if(arr.username === "Alex Rohrberg"){
    srcFound = "/alex.webp";
  }
  if(arr.username === "Hicham Al Baker"){
    srcFound = "/hicham.webp";
  }
  if(arr.username === "julianowahab"){
    srcFound = "/juliano.webp";
    hrefAuthor = "https://www.monet.io/Profile/LLfX2KcUvcZDFSepDRHnb81PU1u2";
  }
  if(arr.username === "Monet"){
    srcFound = "/Monet_logo.webp";
  }

  const likePost = (e, like) => {
    e.preventDefault();
    var updates = {};
    var updatesLikes = {};

    if(like && user.id){
      // update likedPosts for user
      updates['/user_profile/' + user.id + '/likedPosts/' + arr.postID ] = { 
        userPostLiked: arr.uid, 
        createdAt: new Date(), 
      };
      updateData(updates);
      // update like count of post creator
      updatesLikes['/user_profile/' + arr.uid + '/posts/' + arr.postID + "/likeCount"] = increment(1);
      updateData(updatesLikes);
      setLiked(true);
    }else if(!like && user.id){
      updatesLikes['/user_profile/' + arr.uid + '/posts/' + arr.postID + "/likeCount"] = increment(-1);
      updateData(updatesLikes);
      const reff = ref(database, `/user_profile/${user.id}/likedPosts/${arr.postID}`)
      remove(reff);
      setLiked(false);
    }
    else if(!user.id){
      return toast.error("Sign Up to create & like posts😊", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  }

    useEffect(() => {
      if(user.id){
        onValue(ref(database, '/user_profile/' + user.id + '/likedPosts/' + arr.postID), (snapshot) => {
          if(snapshot.exists()){
            setLiked(true);
          }
        });
      }
    }, [liked, user.id]);

  const PostHeader = () => {
    return (
      <div className="flex w-full items-center justify-start relative">
        <div className="flex w-11/12 h-20 items-start justify-start">
          <Link className="flex relative h-full items-center justiyf-start" href={`https://www.monet.io/Profile/${arr.uid}`}>
            <div className="relative w-10 h-10 md:w-14 md:h-14">
              <Image
                // unoptimized
                alt={arr.username}
                fill
                className="rounded-full object-cover"
                src={arr.isBlog ? srcFound : arr.profileURL}
                // sizes="10vw"
                sizes="40px"
              />
            </div>
            <div className="transition duration-500 font-semibold text-base md:text-lg px-3 hover:text-blue-400">{`@${arr.username}`}</div>
          </Link>
          <div className="h-full font-sans flex flex-grow items-center justify-start font-light text-gray-400">
            <div className="text-base md:text-lg"><TimeConverter date={arr.createdAt} /></div>
          </div>
        </div>
        <div className="flex items-center jusitfy-end text-right text-3xl">
          <motion.button
            whileHover={{ scale: 1.1}}
            whileTap={{ scale: 0.9}}
            className=""
            name="options"
            aria-label="options"
            type="button"
            onClick={() => ( optionsOpen ? close() : open())}
          >
          <div className="text-white h-full flex items-center jusitfy-center">
          <svg width="24" className="text-white opacity-60 hover:opacity-100" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14C2.89543 14 2 13.1046 2 12Z" fill="white"></path> <path fillRule="evenodd" clipRule="evenodd" d="M10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12Z" fill="white"></path> <path fillRule="evenodd" clipRule="evenodd" d="M18 12C18 10.8954 18.8954 10 20 10C21.1046 10 22 10.8954 22 12C22 13.1046 21.1046 14 20 14C18.8954 14 18 13.1046 18 12Z" fill="white"></path> </svg>                    </div>
          </motion.button>
        </div>
      </div>
    )
  }

  const PostFooter = () => {
    return (
      <div className="pl-2 pt-5 flex">
        {liked ? 
          <div onClick={(e) => likePost(e, false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-6 h-6 cursor-pointer text-red-500" viewBox="0 0 16 16"> <path d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" fill="red"></path> </svg>
          </div>
        : <div onClick={(e) => likePost(e, true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-6 h-6 text-white hover:text-red-500" viewBox="0 0 16 16"> <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/> </svg>
          </div>
        }
        <div className="pl-5">{arr.likeCount}</div>
      </div> 
    )
  }
  if(arr.verified){
    return <div className="flex items-center justify-center">
      <div className=" w-11/12"><Listings id={arr.UserUid} artwork={arr} />
      </div>
    </div>

  }
    return (
      <div className={`mb-3 ${bgColor} w-full mt-7 items-center justify-center flex border-b border-gray-700 pb-4 shadow-lg rounded-sm `}>
        <div className="flex flex-col text-white w-11/12 mb-3">
          <PostHeader />   
          {!arr.isBlog && <div>
            {arr.postText !== "" && <div className="max-h-max whitespace-normal mx-2 mb-2 mt-3 text-sm w-full md:w-2/3 md:text-lg">
              {arr.postText}
            </div>}
            {arr.postImageURL !== "" && 
              <div className="flex mx-5 items-center justify-start">
                {arr.postImageURL &&  
                  <div className="relative w-80 h-80 md:w-[30rem] md:h-[30rem] lg:w-[40rem] lg:h-[40rem]">
                    <Image fill className="mb-3 object-left object-contain" src={arr.postImageURL} alt="Monet post image" sizes="(max-width: 640px) 100vw, 50vw"/> 
                  </div>
                }
              </div>
            }
            <PostFooter />
          </div> }
          {arr.isBlog && <div className="flex flex-col w-full mb-3">
            <div className="flex flex-col text-white ">
              <div className="w-10/12 transition duration-300 mx-8 md:mx-20 pb-5 md:pb-14 md:max-w-xl shadow-2xl shadow-black hover:shadow-blue-500"><PostCard arr={arr}/></div>
            </div>
          </div>}
          <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
            {optionsOpen && <Modal uid={arr.uid} post={arr} modalOpen={optionsOpen} handleClose={close} />}
          </AnimatePresence>
        </div> 
      </div>
    );
};

export default withUser()(Update);
