import React, { useState } from "react";
import Image from "next/image";
import { SellForm } from "../../components";

const VGallery = ({user, userDB, id}) => {
  const [showForm, setShowForm] = useState(false);
  const art = [
    {
      url: "/VG/dance.jpeg",
      meaning: "Dance (La Danse) is a painting made by Henri Matisse in 1910, at the request of Russian businessman and art collector Sergei Shchukin, who bequeathed the large decorative panel to the Hermitage Museum in Saint Petersburg, Russia. The composition of dancing figures is commonly recognized as a key point of (Matisse's) career and in the development of modern painting. A preliminary version of the work, sketched by Matisse in 1909 as a study for the work, resides at MoMA in New York City, where it has been labeled Dance (I). La Danse was first exhibited at the Salon d'Automne of 1910 (1 October – 8 November), Grand Palais des Champs-Élysées, Paris.",
      artist: "Henri Matisse",
      title: "Dance (1909-1910)",
      dimensions: "260 cm × 391 cm (102.4 in × 153.9 in)",
      medium: "Oil on canvas",
    }
  ];
  
  const Artwork = ({width, height, cardPosition, num}) => {
    const mdW = Math.floor(width/2);
    const mdH = Math.floor(height/2);
    const smW = Math.floor(width/4);
    const smH = Math.floor(height/4);
    const phoneW = Math.floor(width/6);
    const phoneH = Math.floor(height/6);
    return (
      <div className="flex flex-col items-start pt-24 justify-start">
        <div className="flex flex-row w-full items-end justify-start space-x-5">   
          {!cardPosition ? <div className="pb-5">
            <div className="flex bg-gray-800 text-gray-300 text-center cursor-pointer justify-center items-center h-16 w-24 sm:h-12 sm:w-20  border border-gray-600 text-xs">
              {Number.isInteger(num) ? art[num].artist : user.displayName}
            </div>
          </div>
          : null }
          {Number.isInteger(num) ? <div className={`image-container h-[${phoneH}px] w-[${phoneW}px] sm:h-[${smH}px] sm:w-[${smW}px] md:h-[${mdH}px] md:w-[${mdW}px] lg:h-[${width}px] lg:w-[${height}px] `}>
             <Image
              unoptimized
              fill
              sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
              src={art[num].url}
              alt="Use Monet to create 3D experiences"
              className="image shadow-2xl shadow-white hover:shadow-blue-500"
            />
          </div> : 
          <div onClick={() => setShowForm(true)} className={`h-96 w-full flex items-center jusitfy-center bg-black shadow-2xl shadow-blue-900 border-gray-800 border-8`}>
            <div className="text-blue-400 w-full text-center">Add Art</div>
          </div> }
          {cardPosition && <div className="pb-5">
            <div className="flex bg-gray-800 text-center text-gray-300 cursor-pointer justify-center items-center h-16 w-24 sm:h-12 sm:w-20 border border-gray-600 text-xs">
              {Number.isInteger(num) ? art[num].artist : user.displayName}
            </div>
          </div>
          }
        </div>
      </div>
    )
  }

  function UploadArt(){
    const handleClose = (e) => {
      if (e.target.id === "wrapper") {
        setShowForm(false);
      }
    }
    return (
      <div id="wrapper" onClick={handleClose} className="fixed z-30 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center">
        <div className="md:w-[700px] w-[90%] lg:w-[80%] mx-autoflex flex-col">
          <div className="relative mt-10 shadow-2xl shadow-blue-900 bg-black p-2 rounded-lg">
            <div className="absolute top-4 right-3">
              <svg onClick={() => setShowForm(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-7 h-7 text-gray-400 hover:fill-current hover:text-blue-200">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <div className="overflow-y-auto h-[30rem]">
              <div className="text-white m-7 pt-10">We just need some information on the artwork</div>
              <SellForm user={user}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // if(id === "LLfX2KcUvcZDFSepDRHnb81PU1u2"){
  //   return (
  //     <main>
  //       <Head>
  //         <title>Monet Investing Page</title>
  //         <meta name="description" content="Find a curated selection of artists. The Monet Gallery, 
  //         driven by art enthusiasts, seek to empower emerging artists and democratize investing in Blue-chip art."/>
  //       </Head>
  //       <div className="w-screen">
  //         <div className='bg-gray-900'>
  //           {showForm && <UploadArt />}
  //           <div className="pr-5 pl-5 ">
  //             <div className="pt-10">
  //               <h1 className="bg-gradient-to-r from-green-500 via-blue-400 to-green-500 text-transparent bg-clip-text font-extrabold text-center p-1 pt-20 font-sans text-8xl">Monet Gallery</h1>
  //               <h2 className="text-gray-200 font-extrabold text-center p-1 py-10 font-sans text-3xl">My favorite Artists to Celebrate this Month</h2>
  //             </div>
  //             <div className="px-5">
  //               <div className="flex flex-row justify-center">
  //                 <div className="h-2/3 w-2/3 left-40 pr-2"><Artwork cardPosition={true} height={300} width={300} num={0}/></div>
  //                 <div className="h-1/3 w-1/3 pt-20 right-72"><Link prefetch={false} href={`/auction/buy/mundi.png`}><Artwork cardPosition={true} height={500} width={500} num={1} /></Link></div>
  //               </div>
  //               <div className="flex flex-col flex items-center justify-center lg:flex-row">
  //                 <div className="flex flex-row justify-center">
  //                   <div className="h-1/3 w-1/3 pt-20 right-72 pr-5"><Artwork cardPosition={true} height={500} width={500} num={3} /></div>
  //                   <div className="h-2/3 w-2/3 left-40 pr-2"><Artwork cardPosition={true} height={100} width={100} num={2}/></div>
  //                 </div>
  //                 <div className="h-full w-full lg:h-2/3 lg:w-2/3 pt-20 right-72 pl-5 pt-10"><Artwork cardPosition={false} height={200} width={200} num={4}/></div>
  //               </div>
  //             </div>
  //           <div className="h-40"></div>
  //           </div>
  //         </div>  
  //       </div>
  //     </main>
  //   );
  // }else{
  const name = userDB.username.charAt(0).toUpperCase() + userDB.username.slice(1); 
  return (
    <div className="w-screen">
      {showForm && <UploadArt />}
      <div className="pr-5 pl-5 ">
        <div className="pt-10">
          <h1 className="bg-gradient-to-r from-green-500 via-blue-400 to-green-500 text-transparent bg-clip-text font-extrabold text-center p-1 pt-20 font-sans text-6xl md:text-8xl">{name} Gallery</h1>
        </div>
        {user.id === id ? <div className="px-5">
          <div className="flex w-full flex-row justify-center">
            <div onClick={() => setShowForm(true)} className="cursor-pointer w-full pr-2"><Artwork cardPosition={true} height={500} width={500} /></div>
          </div>
          </div> : <div className="text-blue-300 text-center py-40">
          {name} did not share anything yet!
        </div>}
      </div>
    </div>
  );
}

export default VGallery;
