import { motion } from "framer-motion";
import Link from "next/link";
import Share from '../Share';
import {
  useUser,
  withUser,
} from 'next-firebase-auth';
import { get, orderByChild, query, ref, remove, startAt } from "firebase/database";
import { database } from "../../firebase/firebaseApp";
import { sendContactForm } from '../../lib/api';
import { toast } from 'react-toastify';
import { useRouter } from "next/router";

const Modal = ({ handleClose, uid, post}) => {
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
    },
    exit: {
      y: "100vh",
      opacity: 0,
      transition: {
        duration:0.2,
      }
    },
  };

  const user = useAuthUser();
  const router = useRouter();

  function onDelete() {
    if(user && post.Medium){
      const reff = ref(database, `/user_profile/${uid}/artListings/${post.listingsID}`);
      const listingsRef = ref(database, `/artListings/${post.listingsID}`);
      console.log("kook", reff, listingsRef, post.listingsID)
      remove(reff);
      remove(listingsRef);
      handleClose();
      router.replace(router.asPath);
    }
    else if(user){
      const reff = ref(database, `/user_profile/${uid}/posts/${post.postID}`);
      const postsRef = ref(database, `/posts/${post.postID}`);
      remove(reff);
      remove(postsRef);
      const doesNameExist = query(ref(database, 'user_profile'),orderByChild("likedPosts"), startAt(post.postID));
      get(doesNameExist).then((snapshot) => {
        const usersLikedPostKeys =  Object.keys(snapshot.val());
        console.log("users", usersLikedPostKeys);
        const usersLikedPostVals = Object.values(snapshot.val());
        for(let i = 0; i < usersLikedPostVals.length; i++){
          for(let j = 0; j < Object.keys(usersLikedPostVals[i].likedPosts).length; j++){
            if(Object.keys(usersLikedPostVals[i].likedPosts)[j] === post.postID){
              console.log("maddddd", post.postID, Object.keys(usersLikedPostVals[i].likedPosts)[j]);
              const refLikedPost = ref(database, `/user_profile/${usersLikedPostKeys[i]}/likedPosts/${post.postID}`);
              remove(refLikedPost);
            }
          }
        }
      });
      handleClose();
      router.replace(router.asPath);
    }
  }

  async function onReport() {
    const content = "Reported User: " + post.uid + " Name: " + post.username + " PostID: " + post.postID + " Content: "+ post.postText + " Post Image: " + post.postImageURL;
    const newVal =  { uid: user.id, name: user.displayName, email: user.email, subject: "Report", message: content};
    try{
      await sendContactForm(newVal);
      toast.warning("We are looking into it 🧐", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }catch (error) {
      console.log("error:",error.message);
    };
  }

  return(
      <div id="wrapper" onClick={handleClose} className="fixed z-30 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center">
        <div className="md:w-[600px] w-[90%] mx-autoflex flex-col">
          <motion.div
            onClick={(e) => e.stopPropagation()}
            className="flex items-center justify-center"
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
          <div className="relative w-2/3 flex items-center justify-center h-54 bg-gray-900 p-2 rounded-lg">
            <div className="absolute top-4 right-3">
              <svg onClick={handleClose} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-7 h-7 text-gray-400 hover:fill-current hover:text-blue-200">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <div className="flex flex-col text-white justify-center items-center">
              <Share url={"monet.io"} isBlog={false} uid={uid} post={{slug: `/${uid}/${post.postID}`, postID: post.postID, title: post.text, excerpt: post.text, categories: ["art", "Monet"], featuredImage: [{url: post.imageURL}]}}/>
              <div className="flex pb-5 justify-center space-x-5 items-center">
                <Link className="bg-blue-700 font-semibold text-gray-400 hover:text-white hover:bg-blue-500 px-2 py-1 rounded-md text-lg text-center" href={`/${uid}/${post.postID}`}>
                  See Post
                </Link>
                {user.id === uid && <button onClick={onDelete} className="bg-red-800 text-lg text-center font-semibold text-gray-400 hover:text-white hover:bg-red-600 px-2 py-1 rounded-md">Delete Post</button>}
                {user.id !== uid && <button onClick={onReport} className="bg-yellow-700 text-lg text-center font-semibold text-gray-600 hover:text-black hover:bg-yellow-300 px-2 py-1 rounded-md">Report Post</button>}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default withUser()(Modal);