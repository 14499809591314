import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { usePayPalScriptReducer,PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useRouter } from 'next/router';
import axios from 'axios';
import Link from 'next/link';
import { database, updateData } from '../../firebase/firebaseApp';
import Confetti from '../Confetti';
import { get, ref } from 'firebase/database';


const PaypalPayment = ({user, initUserDB, selectedAmount, customAmount, subscribe, orderData}) => {
  const [error, setError] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [subscriptionPlanID, setSubscriptionPlanID] = useState("");
  const tierInfo = {
    0.00: { name: 'Hobby', frequency: 'Monthly', color: 'bg-gray-600' },
    0.00: { name: 'Hobby', frequency: 'Monthly', color: 'bg-gray-600' },
    26.95: { name: 'Emerging', frequency: 'Monthly', color: 'bg-green-600' },
    290.00: { name: 'Emerging', frequency: 'Yearly', color: 'bg-green-600' },
    154.99: { name: 'Red Chip', frequency: 'Monthly', color: 'bg-red-600' },
    1673.89: { name: 'Red Chip', frequency: 'Yearly', color: 'bg-red-600' },
    'Custom Pricing': { name: 'Blue Chip', frequency: 'Custom', color: 'bg-blue-700' },
  };

  const selectedTier = tierInfo[selectedAmount] || { name: 'Unknown', frequency: 'Unknown', color: 'bg-gray-700' };
  const cID = process.env.NEXT_PUBLIC_PAYPAL_AUCTION_LIVE_CLIENT_ID; //SANDBOX => process.env.NEXT_PUBLIC_PAYPAL_AUCTION_CLIENT_ID; 

  //NEXT_PUBLIC_PAYPAL_CLIENT_ID;
  // console.log("payerror", orderData, selectedAmount, cID, process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID);
  const router = useRouter();

  const clientId = process.env.NEXT_PUBLIC_PAYPAL_AUCTION_LIVE_CLIENT_ID; //SANDBOX => process.env.NEXT_PUBLIC_PAYPAL_AUCTION_CLIENT_ID;
  const secret = process.env.NEXT_PUBLIC_PAYPAL_AUCTION_LIVE_SECRET_KEY; //SANDBOX => process.env.NEXT_PUBLIC_PAYPAL_AUCTION_SECRET_KEY;

  const [userDB, setUserDB] = useState(initUserDB);
  console.log((userDB?.subscription && userDB?.subscription?.status === "ACTIVE" && userDB?.subscription?.planID !== "P-freeTier") || (userDB?.subscription?.status === "ACTIVE" && selectedAmount !== 0.00), "here i am popo")

  const PayPalSaleButtons = () => {
    const [{isPending}, paypalDispatch] = usePayPalScriptReducer();

    useEffect(() => {
      // if(scriptLoaded){
        paypalDispatch({
          type: "resetOptions",
          value: {
            "client-id": cID,
            currency: "USD",
          },
        })
      // }
      }, [selectedAmount]);
  
    async function generateAccessToken() {
      const responseToken = await axios.post(
        // 'https://api-m.sandbox.paypal.com/v1/oauth2/token',
        'https://api-m.paypal.com/v1/oauth2/token',
        'grant_type=client_credentials',
        {
          auth: {
            username: clientId,
            password: secret,
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'PayPal-Partner-Attribution-Id': 'MONET_SP_PPCP', // BN Code
          },
        }
      );
      if(!responseToken.data.access_token){
        console.error('Failed to retrieve access token:', error.message);
        return;
      }
      console.log("access token",  responseToken.data.access_token)
      return responseToken.data.access_token;
    }
  
    const createOrder = async (data, actions) => {
      const accessToken = await generateAccessToken();
      console.log("tracking_id here: ", accessToken, orderData)
      try {
        // 'https://api-m.sandbox.paypal.com/v2/checkout/orders'
        const response = await axios.post('https://api-m.paypal.com/v2/checkout/orders', orderData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              'PayPal-Partner-Attribution-Id': 'MONET_SP_PPCP', // BN Code
            }
          }
        );
        console.log("Payapl Order Created", response, accessToken);
        return response.data.id
      }catch (error){
        console.error('Failed to create order', error);
      }
    }

    const onApprove = async (data, actions) => {
      const accessToken = await generateAccessToken();
      try {
        // https://api-m.sandbox.paypal.com/v2/checkout/orders/
        const response = await axios.post(`https://api-m.paypal.com/v2/checkout/orders/${data.orderID}/capture`, orderData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              'PayPal-Partner-Attribution-Id': 'MONET_SP_PPCP', // BN Code
              // 'PayPal-Mock-Response': {"mock_application_codes": "INSTRUMENT_DECLINED"},
            }
          }
        );
        console.log("Payapl WAS APPROVED", response);
        router.push(`${router.asPath}/${data.orderID}`);
      }catch (error){
        console.error('Failed to approve order', error);
        setError(error);
      }
    }
    
    const onError = (err) => {
      setError(err);
      // Display the toast error with the message
      return toast.error(`Somethign went wrong: ${err}`, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    
    return (
      <PayPalButtons
        style={{"layout":"vertical", "color": "silver", "size":"responsive",}}
        disabled={false}
        forceReRender={["0.5", "USD", {"layout":"vertical", "size":"responsive",}]}
        fundingSource={undefined}
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
      />
    );
  }

  if(subscribe){
    const style = {
      shape: 'pill',
      color: 'silver',
      layout: 'vertical',
      label: 'subscribe'
    }

    const onError = (err) => {
      setError(true);
      // Display the toast error with the message
      return toast.error(`Somethign went wrong: ${err}`, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }

    const createSubscription = (data, actions) => {
      let planID = "";
      console.log("00-Subscription Approved", planID, selectedAmount);
      if (selectedAmount === 539.98) {
        planID = "P-2CM444360F605911JMZMEMOQ"; // Hobby Tier Yearly
      }
      if (selectedAmount === 0) {
        planID = "P-3KP94519UG215090YMRHSYUA"; // Hobby
      }
      if (selectedAmount === 290.00) {
        planID = "P-4GG33286VK769924VMZMELPA"; // Emerging Tier Yearly
      }
      if (selectedAmount === 26.95) {
        planID = "P-8H0498883L167625JMRHTWFQ"; // Emerging
      }
      if (selectedAmount === 1673.89) {
        planID = "P-9PF413398P818863GMZMEOFY"; // Red Chip Yearly
      }
      if (selectedAmount === 154.99) {
        planID = "P-7532811002612574XMRHTYVQ"; // Red Chip
      }
      setSubscriptionPlanID(planID)
      return actions.subscription.create({
        plan_id: planID,
      }).then((orderID) => {
        console.log("DID IT APPROVE:", selectedAmount, orderID, planID, data)
        return orderID;
      });
    }

    const approveSubscription  = (data, actions) => {
      console.log("1-Subscription Approved", data);
      // const name = details.payer.name.given_name;
      // TODO: add orders to db user_profile
      // alert(`Transaction completed by ${name}`);
      // add the details to the DB
      const subscriptionUpdates = {};
      const subscriptionData = { 
        planID: subscriptionPlanID || "P-8H0498883L167625JMRHTWFQ",
        amount: selectedAmount,
        order: data,
        date: new Date().toISOString(),
      } 
      subscriptionUpdates['/user_profile/' + user.uid + "/subscription/"] = subscriptionData
      // Add data to firebase RealTime Database
      updateData(subscriptionUpdates);
      console.log("2-Subscription Approved", subscriptionUpdates);
      setUserDB(prevState => ({
        ...prevState,
        subscription: subscriptionData
      }));
      setIsPaid(true)
      // return actions.subscription.capture().then((details) => {
      //   // TODO: update status in DB
      //   console.log("Subscription Approved", details, data);
      //   setIsPaid(true)
      //   const name = details.payer.name.given_name;
      //   // TODO: add orders to db user_profile
      //   // alert(`Transaction completed by ${name}`);
      //   alert(data.subscriptionID);
      //   return toast.success(`Transaction completed by ${name} 😊`, {
      //     position: "top-center",
      //     autoClose: 10000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //   });
      // });
    }

    const formatDate = (dateString) => {
      if(dateString){
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
      }
    };
    
    return (
      <div>
        {isPaid && <Confetti />}
        <div className="text-center mb-14">
          {userDB?.subscription && (userDB?.subscription?.status === "ACTIVE" && userDB?.subscription?.planID === "P-8H0498883L167625JMRHTWFQ" || userDB?.subscription?.planID === "P-4GG33286VK769924VMZMELPA") ? (
            <div>
              <h2 className="text-2xl font-semibold text-white">Congratulations {user.displayName}!</h2>
              <p className="text-gray-300">Started on: {formatDate(userDB?.subscription?.date)}<span className="text-blue-400"> (Renews until you cancel)</span></p>
            </div>
          ) : (
            <div>
              <h2 className="text-2xl font-semibold text-white">
                {isPaid || (userDB?.subscription && userDB?.subscription?.status === "ACTIVE" && userDB?.subscription?.planID !== "P-freeTier") || (userDB?.subscription?.status === "ACTIVE" && selectedAmount !== 0.00) ? `Congratulations ${user.displayName}!` : "Complete Your Subscription"}
              </h2>
              <p className="text-gray-300">
                {isPaid || (userDB?.subscription?.status === "ACTIVE" && userDB?.subscription && userDB?.subscription?.planID !== "P-freeTier") || (userDB?.subscription?.status === "ACTIVE" && selectedAmount !== 0.00) ? "Subscription Completed" : "Selected Plan:"} 
                <span className="text-blue-400">${selectedAmount} ({selectedTier.name} - {selectedTier.frequency})</span>
              </p>
              <p className="text-gray-300">Starts on: {formatDate(userDB?.subscription?.date)}<span className="text-blue-400"> (Renews until you cancel)</span></p>
            </div>
          )}
        </div>
        <PayPalScriptProvider options={{ "client-id": cID, components: "buttons", intent: "subscription", vault: true}}>
          {isPaid || userDB?.subscription?.status !== "ACTIVE" && userDB?.subscription?.planID !== "P-freeTier" || (userDB?.subscription && selectedAmount !== 0.00 ) ? <div className="flex flex-col items-center justify-center w-full">
            <div className="pb-5 w-full text-center">
              <p className="text-gray-300 px-10">{ selectedAmount === 0.00 && userDB?.subscription?.planID === "P-freeTier" || userDB?.subscription?.status !== "ACTIVE" ? "You are missing out on the best features. Join the best creatives maximizing their potential in our emerging plan or continue to the app" : 
                selectedAmount !== userDB?.subscription.amount ? "You already have an active subscription. To manage your subscription please go to your settings in your profile" : "Thank you for subscribing! Your subscription is now active, and you can start using our app immediately."}</p>
              {selectedAmount !== 0.00 && <p className="text-gray-400 text-sm mt-2">We sent you a receipt to your email</p>}
              <button
                className="bg-blue-500 text-white font-semibold py-3 w-full mt-7 rounded-xl hover:bg-blue-600 transition duration-200"
                onClick={() => router.push('monet://')}>
                Open the app
              </button>
            </div>
          </div>
          : <PayPalButtons
            style={{"shape": "pill", "color": "silver", "layout": "vertical", "label": "subscribe"}}
            vault={true}
            createSubscription={createSubscription}
            onApprove={approveSubscription}
            onError={onError}
          />}
        </PayPalScriptProvider>
      </div>
    )
  }

  if(error){
    console.log("pop", error)
    return (
      <div>
        <div className="flex flex-col text-center  items-center justify-center w-full">
        <p className="w-full text-xl pb-5 font-semibold text-red-500">Transaction Failed</p>
        {/* <p className="w-full text-xl pb-3 font-semibold text-gray-300">{error.message}</p> */}
        <div onClick={() => setError(false)} className="transition duration-700 px-3 text-sm mb-5 cursor-pointer hover:border-gray-200 border-gray-500 py-3 border text-center font-semibold text-gray-300">Try Again</div>
        <div className="text-sm py-3 font-semibold text-gray-400">Please reach out to PayPal Customer Support <Link target="_blank" className="transition duration-700 hover:underline text-red-400 hover:text-gray-200" href="https://www.paypal.com">https://www.paypal.com</Link> for more information.</div>
        </div>
      </div>
    )
  }
  return (
    <PayPalScriptProvider options={{ "client-id": cID} }>
      <PayPalSaleButtons/>
    </PayPalScriptProvider> 
  );
}

export default PaypalPayment;