import Link from 'next/link';
import React from 'react';
import Image from 'next/image';

export default function Legal() {
  var authors = [{name: "Juliano Wahab"}, {name: "Monet"}, {name: "Hicham Al Baker"}, {name: "Alex Rohrberg"},];

  function MonetTeam(author, index){
    if(author.name === "Monet"){
      return(
        <div className="pl-2 flex pt-4 flex-col">
        <div key={index} className="flex flex-row sm:mb-2">
          <div className="h-36 w-36 bg-black flex items-center jusitfy-center border border-green-400 rounded-full">
            <div className='flex items-center w-full justify-center'>
            <div className="rounded-full cursor-pointer relative w-24 h-10">
              <Image
                priority
                unoptimized
                alt="Monet Logo"
                fill
                className="object-cover"
                src="/monet-logo.jpg"
                sizes="240px"
              />
            </div>
            <div className="w-2 h-2 mb-7 ">
              <svg className="text-white" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path d="M10,9H7a1,1,0,0,0,0,2h.5v3a1,1,0,0,0,2,0V11H10a1,1,0,0,0,0-2Zm7.38.08a1,1,0,0,0-1.09.21L15,10.59l-1.29-1.3a1,1,0,0,0-1.09-.21A1,1,0,0,0,12,10v4a1,1,0,0,0,2,0V12.41l.29.3a1,1,0,0,0,1.42,0l.29-.3V14a1,1,0,0,0,2,0V10A1,1,0,0,0,17.38,9.08ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" fill="white"></path></svg>            </div>
            </div>
            </div>
            <div className="pl-3 w-2/3 flex pt-4 flex-col">
              <p className="pb-3 bg-gradient-to-r from-white via-pink-400 to-pink-200 inline-block text-transparent bg-clip-text font-extrabold text-2xl md:text-3xl">Create immersive experiences in minutes</p>
              <div className="flex sm:flex-col pl-2">
                <Link prefetch={false} href="/"><p className="text-lg sm: pr-1 text-lg sm:text-2xl sm:pl-3 pb-2 font-medium">{author.name}</p></Link>
                <div className="flex flex-row pl-2">
                  <a aria-label={`${author.name} Instagram`} className="pr-2" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/themonet_co/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 16 16"> 
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" fill="white"></path>
                    </svg>
                  </a>
                  <a aria-label={`${author.name} Linkedin`} className="pr-2" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/themonet/">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 16 16"> 
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" fill="white"></path> 
                  </svg>
                  </a>
                  <a href="https://podcasts.apple.com/us/podcast/monet/id1707768172?itsct=podcast_box_badge&amp;itscg=30200&amp;ls=1" className="rounded-lg w-32 h-14">
                    <Image 
                      width={30}
                      priority
                      unoptimized
                      height={30}
                      src="/podcast.png"
                      alt="Listen on Apple Podcasts" 
                    />
                  </a>
                  {/* <a aria-label={`${author.name} Linkedin`} target="_blank" rel="noopener noreferrer" href="https://twitter.com/Monet_LLC">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 16 16"> 
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" fill="white"></path> </svg>
                  </a> */}
                </div>
              </div>
            </div>
            </div>
            <p className="pt-5 text-base text-gray-200">The Monet platform is fastest and easiest solution to digitize your physical objects and place them in customizable virtual spaces. We make crafting immersive experiences accessible to you.</p>
            <p className="pt-5 text-base text-gray-200"> We are dedicated to showcasing creatives and providing a platform for you to share your vision and work.</p>
            <p className="pt-5 text-base text-gray-200">Only a select few will gain access to our platform and curate immersive experiences. Sign up and DM us on instagram - we want to hear from you!</p>
            <div className="pt-5 text-base text-gray-200">We would love to hear from you! Comment or send us a <Link className="text-blue-400 hover:underline font-semibold" href="/support">contribute request</Link> of your ideas.</div>
        </div>
      );
    }
  }

  function Founders(author, index){
    var srcFound = "";
    var role = "";
    var instagram = "";
    var linkedin = "";
    var color = "";
    if(author.name === "Alex Rohrberg"){
      srcFound = "/alex.webp";
      role = "Developer";
      instagram = "https://www.instagram.com/alexrohrberg/";
      linkedin = "https://www.linkedin.com/in/alexrohrberg/";
    }
    if(author.name === "Hicham Al Baker"){
      srcFound = "/hicham.webp";
      color = "border-purple-500"
      role = "Collection Manager & Lawyer";
      instagram = "https://www.instagram.com/sheesh_kebz/";
      linkedin = "https://www.linkedin.com/in/hicham-al-baker-7a3517138/";
    }
    if(author.name === "Juliano Wahab"){
      srcFound = "/juliano_pitch.jpeg";
      role = "Founder & Artist";
      color = "border-blue-500"
      instagram = "https://www.instagram.com/julianowahab/";
      linkedin = "https://www.linkedin.com/in/julianowahab/";
    }
    return (
      <div key={index} className="flex flex-row mb-2">
        <div className={`relative ${author.name === "Alex Rohrberg" ? "" : "border"} rounded-full ${color} w-20 h-20`}>
          <Image
            // unoptimized
            fill
            sizes="50vw"
            alt={author.name}
            src={srcFound}
            className="rounded-full object-cover"
          />
        </div>
        <div className="w-2/3 pl-2 pt-4 flex flex-col">
          <p className="bg-gradient-to-r from-white via-pink-400 to-pink-200 inline-block text-transparent bg-clip-text font-extrabold">{role}</p>
          <p className="text-lg pl-3 pb-2 font-medium">{author.name}</p>
          <div className="flex flex-row pl-2">
            <a aria-label={`${author.name} Instagram`} className="pr-2" target="_blank" rel="noopener noreferrer" href={instagram}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 16 16"> 
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" fill="white"></path>
              </svg>
            </a>
            <a aria-label={`${author.name} Linkedin`} target="_blank" rel="noopener noreferrer" href={linkedin}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 16 16"> 
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" fill="white"></path> 
            </svg>
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
    <div className="flex relative z-40 flex-col items-center justify-center">
      <div className="border-b border-blue-500 w-full"></div>
      <div className="bg-gray-900 pt-10 h-full w-full pb-3">
        <div className="flex flex-row m-7 text-white text-center items-end justify-center">
          <div className="flex flex-col h-44 w-1/3 space-y-10">
          <Link prefetch={false} href="/about">About</Link>
          <Link prefetch={false} href="/support">Help</Link>
          <Link prefetch={false} href="/legal/conditions-of-sale">Conditions of Sale</Link>
          {/* <Link prefetch={false} href="/sell_art/sell">Start Selling</Link> */}
          </div>
          <div className="flex flex-col text-white h-44 w-1/3 space-y-10">
            <Link prefetch={false} href="/legal/termsncond">Terms of Use</Link>
            <Link prefetch={false} href="/legal/policy">Privacy Policy</Link>
            <Link prefetch={false} href="/support">Contact Us</Link>
          </div>
        </div>
        <div className="text-xl text-white pt-6">
          <p className="text-3xl mx-10 border-b pb-4 items-center">Meet the Team</p>
          <div className="flex sm:flex-row flex-col justify-center px-2 sm:px-10 sm:space-x-10 pt-10">
            <div className="sm:w-5/12">{authors.map((author, index) => (
              <div key={index}>
                {author.name === "Monet" ? null : Founders(author, index)}
              </div>))}
            </div>
            <div className="sm:w-7/12">
              {authors.map((author, index) => (
                <div  key={index} className="flex flex-row">
                  {MonetTeam(author, index)}
              </div>))}
            </div>
          </div>
          <p className="pt-10 text-gray-400 text-sm pl-10 pb-10">© 2023 Monet.io All rights reserved.</p>
        </div>
      </div>
    </div>
    </div>
    );
  }
