

export function convertTime(date){
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  var interval = seconds / 31536000;

  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + "y"
  }
  if (interval > 2) {
    return Math.floor(interval) + "y"
  }

  interval = seconds / 2592000;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + "mo"
  }
  if (interval > 2) {
    return Math.floor(interval) + "mo"
  }

  interval = seconds / 86400;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + "d"
  }
  if (interval > 2) {
    return Math.floor(interval) + "d"
  }
  
  interval = seconds / 3600;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " h"
  }
  if (interval > 2) {
    return Math.floor(interval) + " h"
  }

  interval = seconds / 60;
  if (interval > 2) {
    return Math.floor(interval) + " min"
  }
  
  return Math.floor(interval) + " min"
}

const TimeConverter = ({date}) => {
  const dateC = convertTime(date);
  return <div className="text-gray-400 text-medium text-sm">{dateC}</div>;
}

export default TimeConverter;