import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from 'react';
import TimeConverter from "../TimeConverter";
import { onValue, ref } from "firebase/database";
import { database } from "../../firebase/firebaseApp";
import Loader from "../Loader";
import dynamic from "next/dynamic";
// import { motion } from 'framer-motion';
// import { database, updateData } from '../../firebase/firebaseApp';
const Modal = dynamic(() => import('../../components/Modal'), {
  loading: () => 'Loading...',
});

const Listings = ({userData, id, user}) => {
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState(""); 
  const [errorMessage, setErrorMessage] = useState(null);
  const [userDB, setUserDB] = useState(userData);

  useEffect(() => {
    if(!userData){
      onValue(ref(database, '/user_profile/' + id), (snapshot) => {
        if(snapshot.exists()){
          setUserDB(snapshot.val());
        }
      });
    }
  }, [])

  if(!userDB) return <div className="py-20"><Loader /></div>

  const PostHeader = ({artwork}) => {
    const [optionsOpen, setOptionsOpen] = useState(false);
    const close = () => setOptionsOpen(false);
    const open = () => setOptionsOpen(true);
    console.log("artwork", userDB.artListings)
    return (
      <div className="flex w-full items-center justify-center relative">
        <div className="flex w-full h-20 items-start justify-start">
          <Link className="flex relative h-full items-center justiyf-start" href={`https://www.monet.io/Profile/${id}`}>
            <div className="relative w-10 h-10 md:w-14 md:h-14">
              <Image
                unoptimized
                alt={userDB.username}
                fill
                className="rounded-full object-cover"
                src={userDB.profileURL}
                sizes="40px"
              />
            </div>
            <div className="transition duration-500 font-semibold text-md md:text-lg px-3 hover:text-blue-400">{`@${userDB.username}`}</div>
          </Link>
          <div className="h-full font-sans flex flex-grow items-center justify-start font-light text-gray-400">
            <div className="text-md md:text-lg"><TimeConverter date={artwork.createdAt} /></div>
          </div>
        </div> 
        <div className="w-1/12 text-right text-3xl">
          <div
            className="text-white"
            onClick={() => ( optionsOpen ? close() : open())}
          >
          <div className="hover:text-white pb-5 text-gray-400">...</div>
          <div>
            {optionsOpen && <Modal uid={user.id} post={artwork}  modalOpen={optionsOpen} handleClose={close} />}
          </div>
          </div>
        </div>
      </div>
    )
  }

  const PostFooter = () => {

    const handleAmountClick = (amount) => {
      // setCustomAmount("")
      setSelectedAmount(amount);
      setErrorMessage(null);
    };

    const handleCustomAmountChange = (event) => {
      // event.preventDefault();
      setCustomAmount(event.target.value);
      setSelectedAmount(null);
    };

    const handleCustomAmountBlur = (event) => {
      const value = event.target.value.trim();
      if (value !== "") {
        const amount = parseFloat(value);
        if (isNaN(amount) || amount < 0.01 || amount > 10000) {
          setErrorMessage("Please enter a valid amount between $0.01 and $10,000.");
        } else {
          setCustomAmount(amount.toFixed(2));
          setSelectedAmount(amount.toFixed(2));
          setErrorMessage(null);
        }
      } else {
        setCustomAmount("");
        setSelectedAmount(null);
        setErrorMessage(null);
      }
    };

    const Buttons = () => {
      return (
        <div className="space-y-3 flex flex-col items-center justify-center w-full">
          <div className="flex items-center justify-center space-x-3 w-full">
            <button
              type="button"
              className={`py-1 w-24 rounded-lg text-xl font-semibold ${selectedAmount === 100 ? 'bg-blue-500 text-white' : 'text-white border'} px-4`}          
              onClick={() => handleAmountClick(100)}
            >
              $10
            </button>
            <input
              className={`py-1 rounded-lg w-24 text-xl font-semibold ${customAmount !== "" ? 'bg-blue-500 text-white' : 'bg-gray-600 placeholder-gray-300 text-white'} px-4`}          
              placeholder="Other"
              value={customAmount}
              onChange={handleCustomAmountChange}
              onBlur={handleCustomAmountBlur}
            />
          </div>
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        </div>
      );
    }

    return (
      <div className="pt-10">
        {/* <Buttons /> */}
        {/* <div className="py-5 pt-10 flex w-full items-center justify-center">
          <ProgressBar artwork={artwork} />
        </div> */}
        <div className="flex py-4  items-center text-center justify-center">
          <Link href="https://www.paypal.com/donate/?hosted_button_id=L4UTKJMLVNXY2"
            type="button"
            className={`py-2 px-5 rounded-lg text-xl font-semibold text-white px-14  ${customAmount !== "" || selectedAmount !== null ? 'bg-green-400 text-black ' : 'bg-blue-500'}`}          
          >
            Donate Now
          </Link>
        </div>
        <div className="text-sm font-semibold py-3 w-full text-start">Description</div>
        <div className="text-sm whitespace-normal text-center">Get a piece of my artwork when you donate to support a lawsuit against The Atlanta Police Department who hopes to turn 85 acres of forest into a tactical training compound.</div>
        <div className="text-sm whitespace-normal font-medium py-3 w-full text-center">Learn more on Emmy's artwork and the deforestation in Atlanta by reading,&nbsp;
        <Link href="https://www.monet.io/blog/art-as-activism-protecting-atlantas-forests" className="text-blue-400">"Art as Activism: Protecting Atlanta's Forests"</Link></div>
      </div>
    );
  }

  const ProgressBar = () => {
    const ONE_WEEK = 10 * 24 * 60 * 60 * 1000; // One week in milliseconds
    const now = new Date().getTime();
    const createdAt = new Date(artwork.createdAt).getTime();
    const elapsed = Math.min(1, (now - createdAt) / ONE_WEEK); // Cap progress at 100%
    const remaining = ONE_WEEK - (now - createdAt); // Time remaining in milliseconds
    const [progress, setProgress] = useState(elapsed);
      
    useEffect(() => {
      const timer = setInterval(() => {
        const ONE_WEEK = 10 * 24 * 60 * 60 * 1000; // One week in milliseconds
        const now = new Date().getTime();
        const createdAt = new Date(artwork.createdAt).getTime();
        const elapsed = Math.min(1, (now - createdAt) / ONE_WEEK); // Cap progress at 100%
        // const remaining = ONE_WEEK - (now - createdAt); // Time remaining in milliseconds
        setProgress(elapsed);
      }, 1000);
      return () => clearInterval(timer);
    }, [artwork.createdAt]);
    
    const formatTime = (time) => {
      const days = Math.floor(time / (24 * 60 * 60 * 1000));
      const hours = Math.floor((time % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
      const minutes = Math.floor((time % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((time % (60 * 1000)) / 1000);
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };
  
    return (
      <div className="relative w-3/4 my-3 h-2 rounded-full bg-gray-600">
        <div className={`absolute bottom-2 right-0 text-sm ${
            progress >= 0.88 ? 'text-red-500' : 'text-green-500'
          }`}>{formatTime(remaining)}</div>
        <div
          className={`absolute top-0 left-0 h-full rounded-full transition-all duration-500 ${
            progress >= 0.88 ? 'bg-red-500' : 'bg-green-500'
          }`}
          style={{ width: `${progress * 100}%` }}
        />
      </div>
    )
  };
  
  const PostMid = ({artwork}) => {
    return (
      <div className="flex flex-col max-w-2xl w-full h-full items-start justify-start relative">
        <div className="text-xl w-full font-semibold text-center px-2">
          {`${artwork.Title} (${new Date(artwork.createdAt).getFullYear()})`}
        </div>
        <div className="text-sm pb-3 w-full text-gray-400 font-medium text-center">
          {`${artwork.listingsID === "Emy-CD-STOP-COP-CITY-08e18455-b734-4224-b1a3-4839fefacb31" ? "Mixed media" : ""} ${artwork.Medium.toLowerCase()} ${artwork.width} x ${artwork.height} ${artwork.in_cm ? "cm" : "in"}`}
        </div>
        <div className="relative w-full h-full">
          <Image
            unoptimized
            alt={artwork.Title}
            fill
            className={`${artwork.listingsID === "Emy-CD-STOP-COP-CITY-08e18455-b734-4224-b1a3-4839fefacb31" ? "shadow-green-400" : "shadow-gray-400" } object-cover object-top border-4 border-black shadow-2xl flex items-start justify-start`}
            src={artwork.photo1}
            sizes="100vw"
          />
          {!artwork.verified && <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-75">
            <span className="text-white font-bold text-2xl">Pending Verification</span>
            <span className="text-white font-medium text-md">We will update you on the status very soon</span>
          </div>}
        </div>
      </div>
    )
  }

  const Listing = ({index, artwork}) => {
    return (
      <div key={index} className="max-w-4xl mb-3 bg-black w-full items-center justify-center flex shadow-lg rounded-sm">
        <div className="flex items-center justify-center flex-col text-white w-full mb-3">
          <PostHeader artwork={artwork} />   
          <div className="flex items-center justify-center md:space-x-3 flex-col lg:flex-row w-full">
            <div className="flex items-center justify-center w-full h-[25rem] sm:h-[30rem] md:h-[32rem]">
              <PostMid artwork={artwork} />   
            </div>
            <div className="w-full md:w-2/3">
              {artwork.listingsID === "Emy-CD-STOP-COP-CITY-08e18455-b734-4224-b1a3-4839fefacb31" && <PostFooter artwork={artwork} />}
            </div>
          </div>
        </div> 
      </div>
    );
  }
  return (
    <div className="mb-3 flex flex-col bg-black w-full mt-7 items-center justify-center flex border-b border-gray-700 pb-4 shadow-lg rounded-sm">
      {userDB.artListings && Object.values(userDB.artListings).map((artwork, index) => {
        if(artwork.verified || id === user.id){
          return (
            <div key={index} className="w-full pb-10">
              <Link href={`/auction/seller=${artwork.UserUid}&artid=${artwork.listingsID}`}>
                <Listing index={index} artwork={artwork} />
              </Link>
            </div>
          );
        }else return <div className="py-20 w-full">
          <div className="text-blue-300 text-center pt-10 pb-20 text-md ">
            {userDB.username} did not list any artwork for sale yet
          </div>
        </div>
      })}
    </div>
  );
};

export default Listings;
