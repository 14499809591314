import { deleteObject, getDownloadURL, getStorage, ref as sRef, uploadBytes } from 'firebase/storage'
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify'
import { database, updateData } from '../../firebase/firebaseApp';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from "next/router";
import Link from 'next/link';
import { endAt, equalTo, get, limitToFirst, onValue, orderByChild, push, query, ref, set, startAt } from 'firebase/database';
import Image from 'next/image';

const PostInput = ({ user, uid}) => {
  const refContainer = useRef('');
  const [taggedUserRec, setTagRec] = useState([]);
  const userstagged = Object.values(taggedUserRec);
  const [tagPost, setTagPost] = useState(false);

  // set limit to 3 support request per day per user
  var submitCount = 0;
  const router = useRouter();

  // variable to store request information
  const initValues = { uid: "", name: "", profilePic: "", message: "" };
  const [formData, setFormData] = useState({ comment: ''});

  const initState = { values: initValues };
  const [state, setState] = useState(initState);
  const { values, isLoading } = state;
  const reference = useRef(values);

  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [loadingUpdate, setLoading] = useState(false);

  useEffect(() => {
    if(formData.comment.includes("@") === false){
      setTagPost(false);
    }
  },[taggedUserRec, userstagged])

  const onInputChange = (e) => {
    const { target } = e;
    if(e.target.value.length < 270){
      TaggedUser();
      setFormData((prevState) => ({
        ...prevState,
        [target.name]: target.value,
      }));
    }
  };

  const TaggedUser = () => {
    const taggedUser = formData.comment.toLowerCase().match(/@(\w+)/);
    if(taggedUser !== null && tagPost === false){
      const poststop20 = query(ref(database, "/user_profile"), orderByChild('username'), startAt(taggedUser[1]), endAt(taggedUser[1] + "\uf8ff"), limitToFirst(5));
      onValue(poststop20, snapshot => {
        const data = snapshot.val();
        setTagRec(data);
     });
    }
  }

  const handlePostSubmission = async () =>  {
    const { comment } = formData;
    ++submitCount;
    if (!user) {
      return toast.error("Sign in to continue", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    if (submitCount > 3) {
      return (toast.error("Too many requests ⛔ try again later", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }));
    }
    if (comment.length < 2 && !file) {
      return toast.error("Add content to Post 😊", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }

    const newVal =  { uid: uid, name: user.displayName, profilePic: user.profileURL, message: comment};
    reference.current = newVal;

    if (loadingUpdate)
      return

    try {
      setLoading(true);
      const updates = {};
      const postID = uuidv4() + user.username;
      const storage = getStorage();
      const profilePicRef = sRef(storage, `userPosts/${uid}/images/${postID}`);
      if(file) {
        // Make sure the file is smaller than 10MB
        if (file.size > 10000000) {
          setSelectedFile(null);
          setFile(null);
          return toast.error("Photo quality is too high 🤔", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        // upload Image to Firebase Storage db to match user-uid
        await uploadBytes(profilePicRef, file).then((snapshot) => {
          console.log('Uploaded a blob or file!', snapshot);
        });
        await getDownloadURL(profilePicRef).then((url) => {
          refContainer.current = url;
        }).catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              console.log("storage/object-not-found");
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              console.log("storage/unauthorized");
              break;
            case 'storage/canceled':
              // User canceled the upload
              console.log("storage/canceled");
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              console.log("storage/unknown");
              break;
          }
        });
      }

      updates['/user_profile/' + uid + '/' + 'posts/' + postID] = {  
        text: formData.comment,
        imageURL: refContainer.current, 
        createdAt: new Date(), 
        likeCount: 0,
      };
      updateData(updates);
      const postUpdates = {};
      postUpdates['/posts/' + postID] = {  
        postText: formData.comment,
        postImageURL: refContainer.current, 
        createdAt: new Date(), 
        postLikeCount: 0,
        profileURL: user.profileURL,
        uid: uid,
        username: user.username,
        postID: postID,
      };
      // Add data to firebase RealTime Database
      updateData(postUpdates);

      // const desertRef = sRef(storage, `userPosts/${uid}/images/`);
      // deleteObject(desertRef).then(() => {
      //   // File deleted successfully
      // }).catch((error) => {
      //   // Uh-oh, an error occurred!
      //   console.log(error)
      // });

      toast.success("Your Post is Live!😆", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setState(initState);
      setLoading(false);
      setFile(null);
      setSelectedFile(null);
      setFormData({ comment: ''})
      router.replace(router.asPath);
      setTagRec([]);
      setTagPost(false);
    } catch (error) {
      setState((prev) => ({
        ...prev,
        isLoading: false,
        error: error.message,
      }));
    }
  };

  const addImageToPost = (e) => {
    const reader = new FileReader();
    if (!e.target.files[0].type.startsWith('image/')) {
      setSelectedFile(null);
      setFile(null);
      return toast.error("We only accept images at the moment 🖼️", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0])
      setFile(e.target.files[0]);
    }
    reader.onload = (readerEvent) => {
      setSelectedFile(readerEvent.target.result)
    }
  }

  function handleTag(username){
    const parts = formData.comment.split(/(@\w+)/);
    const mentions = parts.filter((part) => /^@\w+/.test(part));
    formData.comment.replace(mentions[0], "@" + username)
    // remove the tag when clicked on 
    setFormData((prevState) => ({
      ...prevState, ["comment"]: formData.comment.replace(mentions[0], "@" + username + " ")}));
    setTagRec([]);
    setTagPost(true);
  }

  return (
    <div className="flex flex-col w-full max-w-screen-lg mb-10">
      <div className="flex flex-col items-center justify-center">
        <div className="block mb-2 pb-5 text-center flex items-center justify-center text-md sm:text-lg font-semibold text-blue-200">Show off your creativity by sharing your Art</div>
          <div className="flex pl-3 justify-start w-full">
            {selectedFile && (
              <div className="relative mb-4">
                <div className='z-20 absolute w-8 h-8 bg-[#15181c] hover:[#272c26] text-red-400 bg-opacity-75 rounded-full flex items-center justify-center top-1 left-1 cursor-pointer' onClick={() => setSelectedFile(null)}>X</div>
                <div className="relative h-80 w-80">
                  <Image
                    src={selectedFile}
                    alt="MonetUser Post Image Art"
                    className='rounded-2xl max-h-80 object-contain' 
                    fill
                  />
                </div>
              </div>
              )}
          </div>
        <textarea name="comment" placeholder=" Speak your mind..." className="bg-gray-900 border h-20 rounded-lg border-blue-400 w-full mb-5 outline-none text-gray-200" value={formData.comment} onChange={onInputChange} id="content"  />
        <div className="absolute mt-40 right-10 text-gray-400 text-sm">{formData.comment.length} / 270</div>
      </div>
      <div className="flex pb-3 flex-col justify-start items-start mx-5">
        {formData.comment.includes("@") && taggedUserRec && userstagged.map((user, index) => 
          <div key={index} onClick={() => handleTag(user.username)} className="flex w-full cursor-pointer border-t py-2 border-gray-600 my-1 items-center justify-start">
            <div className="relative h-10 w-10 mr-3">
              <Image 
                sizes="(max-width: 768px) 100vw,
                (max-width: 1200px) 50vw,
                33vw"
                unoptimized
                src={user.profileURL === undefined ? "/blank-profile-picture.webp" : user.profileURL}
                fill
                className="object-cover object-center rounded-full"
                alt={`${user.username} Profile picture in Monet`}
              />
            </div>
            <div className="text-white font-semibold text-md">{user.username}</div>
          </div>
        )}
      </div>
      {!loadingUpdate && (
        <div className='flex justify-start items-center'>
          <div className='flex gap-4 text-[20px] text-[#1d9bf0]'>
            <label htmlFor="file" className="px-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="text-white" viewBox="0 0 16 16"> <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/> <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/> </svg>
            </label>
            <input id="file" type="file"
              hidden
              onChange={addImageToPost}
            />
          </div>
          <button
            className="bg-blue-500 text-white rounded-full px-4 py-1.5 font-bold shadow-md disabled:opacity-50 disabled:cursor-default"
            disabled={!formData.comment.trim() && !selectedFile}
            onClick={handlePostSubmission} >
            Post
          </button>
          <Link href={user.verifiedSeller ? "/sell_art/" : "/sell_art/sell"} className="bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-400 hover:to-blue-400 text-gray-900 rounded-full px-4 mx-5 py-1.5 font-bold shadow-md hover:bg-blue-400 disabled:hover:bg-gray-400 disabled:opacity-50 disabled:cursor-default">
            Sell Art
          </Link>
        </div>
      )}
    </div>
  );
};

export default PostInput;
