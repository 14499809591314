import { useState, ChangeEvent, KeyboardEvent, useEffect} from "react"


type SellData = {
  artistName: string,
  Title: string,
  Medium: string,
  year: string,
  height: string,
  width: string,
  depth: string,
  in_cm: boolean,
  provenance: string,
  artworkAddress: string,
  AdditionalInfo: string,
  tags: Array<string>,
  photo1: any,
  photo2: any,
  photo3: any,
}


type UserFormProps = SellData & {
  updateFields: (fields: Partial<SellData>) => void
}

interface SpaceSeparatedInputProps {
  onAdd: (value: string) => void;
}

const SpaceSeparatedInput: React.FC<SpaceSeparatedInputProps> = ({ onAdd }) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value.toLowerCase());
  };

  const handleInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ' ') {
      if (inputValue.trim() !== '') {
        onAdd(inputValue.trim());
      }
      setInputValue('');
    }
  };

  return (
    <div className="flex items-center justify-center text-xl">#
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        placeholder="Add a tag"
        className="bg-gray-900 m-2 text-blue-100 text-sm focus:ring-blue-500 block ml-1 p-2.5"
      />
    </div>
  );
};


export function ArtForm({artistName, Title, Medium, year, height, width, depth, in_cm, tags, provenance, artworkAddress, AdditionalInfo, updateFields}: UserFormProps) {
  const [hashtags, setHashtags] = useState<string[]>(tags);

  useEffect(() => {
    console.log("Tags updated:", tags);
  }, [tags]);

  const handleDelete = (value: string) => {
    setHashtags((prevValues) => prevValues.filter((val) => val !== value));
    updateFields({tags: hashtags.filter((val) => val !== value)});
  }

  const handleAddValue = (value: string) => {
    if (hashtags.length >= 10) {
      // Maximum length reached, display an error message or take appropriate action
      console.log('Maximum length reached');
      return;
    }

    if (hashtags.includes(value)) {
      // Duplicate value entered, display an error message or take appropriate action
      console.log('Duplicate value entered');
      return;
    }

    if (value.length > 50) {
      // Value exceeds character limit, display an error message or take appropriate action
      console.log('Value exceeds character limit');
      return;
    }
    setHashtags((prevValues) => {
      const updatedHashtags = [...prevValues, value];
      updateFields({ tags: updatedHashtags });
      return updatedHashtags;
    });
  };

  return (
    <div className="flex flex-col justify-center max-w-4xl w-full">
      <label className="block mb-2 text-xl font-semibold text-gray-200">
        Details
      </label>
      <div className="flex w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center w-1/2">
          <div className="py-2">
            <label className="block mb-2 text-md font-medium text-blue-300">
              Artist Name
            </label>
            <div className="flex items-center justify-center text-blue-300">
            <input onChange={e => updateFields({artistName: e.target.value})} value={artistName} type="text" name="name" id="name" className="bg-gray-900 text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ml-1 p-2.5"
            placeholder="Enter full name"
            required /></div>
          </div>
          <div className="py-2">
            <label className="block mb-2 text-md font-medium text-blue-300">
              Title
            </label>
            <div className="flex items-center justify-center text-blue-300">
            <input onChange={e => updateFields({Title: e.target.value})} value={Title} type="text" name="Title" id="Title" className="bg-gray-900 text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ml-1 p-2.5"
            placeholder="Add title or write 'Unknown"
            required /></div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-1/2">
          <div className="py-2">
            <label className="block mb-2 text-md font-medium text-blue-300">
              Medium
            </label>
            <select value={Medium} onChange={e => updateFields({Medium: e.target.value})} required className="bg-black text-blue-50 h-7 w-56 max-w-xs focus:outline-none" name="medium" id="medium">
              <option value="Select">Select</option>
              <option value="Painting">Painting</option>
              <option value="Sculpture">Sculpture</option>
              <option value="Photography">Photography</option>
              <option value="Print">Print</option>
              <option value="Drawing, Collage, or other Work on Paper">Drawing, Collage, or other Work on Paper</option>
              <option value="Mixed Media">Mixed Media</option>
              <option value="Performance Art">Performance Art</option>
              <option value="Fashion Design & Wearable Art">Fashion Design & Wearable Art</option>
              <option value="Video/Film/Animation">Video/Film/Animation</option>
              <option value="Other">Other</option>
            </select>
            </div>
            <div className="py-2">
              <label className="block mb-2 text-md font-medium text-blue-300">
                Year
              </label>
              <div className="flex items-center justify-center text-blue-300">
              <input type="text" name="Year" id="Year" className="bg-gray-900 text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ml-1 p-2.5"
              placeholder="YYYY"
              required onChange={e => updateFields({year: e.target.value})} value={year}/></div>
          </div>
        </div>
      </div>
        <div className="flex flex-col w-full">
          <div className="py-2 flex">
            <div className="py-2 px-3 flex flex-col items-center justify-center">
              <label className="block mb-2 text-md font-medium text-blue-300">
                Height
              </label>
              <div className="flex items-center justify-center text-blue-300">
              <input type="text" name="Height" id="Height" className="bg-gray-900 text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ml-1 p-2.5"
              required onChange={e => updateFields({height: e.target.value})} value={height}/></div>
            </div>
            <div className="py-2 px-3 flex flex-col items-center justify-center">
              <label className="block mb-2 text-md font-medium text-blue-300">
                Width
              </label>
              <div className="flex items-center justify-center text-blue-300">
              <input type="text" name="Width" id="Width" className="bg-gray-900 text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ml-1 p-2.5"
              required onChange={e => updateFields({width: e.target.value})} value={width}/></div>
            </div>
            <div className="py-2 px-3">
              <label className="block mb-2 text-md flex items-center justify-center font-medium text-blue-300">
                Depth&nbsp;<div className="text-xs text-gray-300">(Optional)</div>
              </label>
              <div className="flex items-center justify-center text-blue-300">
              <input onChange={e => updateFields({depth: e.target.value})} value={depth} type="text" name="Depth" id="Depth" className="bg-gray-900 text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ml-1 p-2.5"
              /></div>
            </div>
          </div>
          <div className="flex items-center justify-center py-2">
            <div className="mx-3 flex items-center justify-center">
              <label className="text-blue-50 text-xl">
                <input required onChange={e => updateFields({in_cm: false})} className="h-5 w-5 scale-125" type="radio" name="dim"/> in
              </label>
            </div>
            <div className="mx-3 flex items-center justify-center">
              <label className="text-blue-50 text-xl">
                <input required onChange={e => updateFields({in_cm: true})}  className="h-5 w-5 scale-125" type="radio" name="dim"/> cm
              </label>
            </div>
          </div>
          <div className="py-2">
            <label className="block mb-2 text-md font-medium text-blue-300">
              Provenance 
            </label>
            <label className="block mb-2 text-sm font-semibold text-white">
              Required if you are NOT the artist
            </label>
            <label className="block mb-2 text-sm font-medium text-gray-300">
              Please list any documentation you have that proves how you acquired the work, such as:<br/><br/>
              - Invoices from previous owners<br/>
              - Certificates of authenticity<br/>
              - Gallery exhibition catalogues<br/><br/>
            </label>
            <div className="flex items-center justify-center text-blue-300">
              <input type="text" name="Provenance" id="Provenance" className="bg-gray-900 text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ml-1 p-2.5"
              placeholder="Describe how you acquired the work"
              onChange={e => updateFields({provenance: e.target.value})} value={provenance}/>
            </div>
          </div>
          <div className="py-2">
            <label className="block mb-2 text-md font-medium text-blue-300">
              Artwork Address
            </label>
            <div className="flex items-center justify-center text-blue-300">
            <input type="text" name="name" id="name" className="bg-gray-900 text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ml-1 p-2.5"
            placeholder="Enter the city, state, country, and zipcode"
            required onChange={e => updateFields({artworkAddress: e.target.value})} value={artworkAddress}/></div>
          </div>
          <div className="py-2">
            <label className="block mb-2 text-md font-medium text-blue-300">
              Tags
            </label>
            <label className="block mb-2 text-sm font-medium text-gray-300">
              Please add 2-3 hashtags to describe the work
            </label>
            <div className="flex flex-wrap border border-gray-700 w-full items-center justify-center text-blue-300">
              {hashtags.map((hashtag,index) => 
                <div key={index} onClick={() => handleDelete(hashtag)} className={`border flex items-center justify-center rounded-md py-1 px-3 m-2 whitespace-nowrap cursor-pointer text-white}`}>
                  #{hashtag} <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="ml-2 text-white border rounded-full" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path> </svg>
                </div>
              )}
              <SpaceSeparatedInput onAdd={handleAddValue} />
            </div> 
            <div className="w-full text-end text-white text-xs pt-2">{hashtags.length + "/10 tags"}</div>
          </div>
      </div> 
    </div>
  )
}

