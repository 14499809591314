// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getDatabase, update, ref, onValue} from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain:  process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
export let analytics;
if(typeof window !== undefined){
  analytics = isSupported().then(yes => yes ? getAnalytics(app) : null);
}

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);

// Export function to initialize firebase
export const initFirebase = () => {
  return app;
};

export const auth = getAuth(app);

export function updateData(updates){
  update(ref(database), updates);
}

export async function getData(uid){
  var job = "";
  var bio = "";
  onValue(ref(database, '/user_profile/' + uid), (snapshot) => {
    job = snapshot.val().job_position;
    bio = snapshot.val().bio;
    // ...
  }, {
    onlyOnce: true
  });
  return {job: job, bio: bio }
}


// export async function getData(uid: string){
//   var job: string = "";
//   var bio: string = "";
//   onValue(ref(database, '/user_profile/' + uid), (snapshot) => {
//     job = snapshot.val().job_position;
//     bio = snapshot.val().bio;
//     // ...
//   }, {
//     onlyOnce: true
//   });
//   return {job: job, bio: bio }
// }

