import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Link from 'next/link';
import { getSimilarPosts, getRecentPosts } from '../services';
import Image from 'next/image';

const PostWidget = ({ categories, slug }) => {
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    if (slug) {
      getSimilarPosts(categories, slug).then((result) => {
        setRelatedPosts(result);
      });
    } else {
      getRecentPosts().then((result) => {
        setRelatedPosts(result);
      });
    }
  }, [slug]);

  function bStyle(index){
    if (index !== (relatedPosts.length -1)) {
      return "border-b border-blue-200"
    }
  }

  return (
    <div className="bg-gray-1000 shadow-lg rounded-lg p-8">
      <h3 className="text-2xl mb-1 text-blue-100 font-base pb-4">{slug ? 'Related Posts' : 'Recent Posts'}</h3>
      {relatedPosts.map((post, index) => (
        <div key={index} className="w-full mb-4">
          <Link prefetch={false} href={`/blog/${post.slug}`} className={"flex items-center justify-left flex-row pb-4 " + bStyle(index)}>
              <div className="absolute shadow-md inline-block"> 
                <div className="flex items-center justify-center relative h-12 w-12">
                  <Image
                    unoptimized
                    fill
                    sizes="(max-width: 768px) 100vw,
                  (max-width: 1200px) 50vw,
                  33vw"
                    alt={post.altImage}
                    src={post.featuredImage[0].url}
                    className="object-center object-cover"
                  />
                </div>
              </div>
              <div className="flex-grow pl-16">
                <div className="text-gray-500 text-xs">{moment(post.createdAt).format('MMM DD, YYYY')}</div>
                <div href={`/blog/${post.slug}`} className="text-sm text-blue-100 hover:text-blue-300" key={index}>{post.title}</div>
              </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default PostWidget;
