

export function CompletedForm() {
  return (
    <>
      <div className="flex flex-col">
        <label className="block mb-2 text-xl font-semibold text-green-300">
          Thank You for choosing Monet!
        </label>
        <label className="pl-3 text-center block mb-2 text-lg font-medium text-blue-300">
          Expect an email on the status of your verification in 2-3 business days
        </label>
      </div> 
    </>
  )
}