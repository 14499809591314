import React from 'react';
import Link from 'next/link';

const PolicyText = () => {
  const termsnCond = () => <Link prefetch={false} className="text-blue-400" href="/legal/terms-and-cond">Terms and Conditions</Link>

  return (
    <div>
      <div className="pt-10">
        <h1 className="text-5xl sm:text-9xl m-10 pt-10 text-white">Privacy Policy</h1>
        <p className="m-10 text-white text-sm pt-20">
          This Privacy Policy (“Privacy Policy”) and any terms expressly incorporated herein apply to your access to and/or use of any services made available by Monet Gallery Inc. (“Monet”) 
          through theMonet.co or Monet.io (“Website”), mobile applications, and/or APIs/Developer Tools and any other related services provided by Monet or its affiliate, subcontractors or third-party 
          service providers, including the Platform.<br/><br/><br/>You agree and understand that by accessing or using the Monet Materials, you have affirmed that you are at least 18 years
          old, have the legal capacity to consent to this Privacy Policy, and agree to be bound by the policies and practices of this Privacy Policy in their entirety.
        </p>
        <h2 className="text-xl m-10 pt-5 text-white">Introduction</h2>
        <div className="m-10 text-white">This Privacy Policy, together with our {termsnCond()}, governs how Monet collects, processes, uses, discloses, shares, and protects Personal Data we collect from or in relation to you. The terms “we”, “us” and “our” refer to Monet and its Affiliates and related companies. It is important that you read this Privacy Policy together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This Privacy Policy supplements the other notices and is not intended to override them.</div>
        <p className="m-10 text-white">Personal Data means data, whether true or not, about an individual who can be identified from that data or from that data and other information to which the
          organization has or is likely to have access. Monet values your privacy.<br/><br/><br/>The governing language of this Privacy Policy is English. Any other language translation is provided 
          for convenience only and in case of any difference between the English version and the translated versions of this Privacy Policy, the English version shall prevail.<br/><br/><br/>If you have 
          queries, comments, or concerns regarding our Privacy Policy or wish to: (i) withdraw any consent provided to us in respect of the collection, use, and/or disclosure by us of Personal Data 
          about you for any purpose; (ii) receive information about the Personal Data we have in relation to you; or (iii) update Personal Data we have in relation to you, please contact 
          the team at julianomonet@gmail.com.<br/><br/><br/>By accessing or using Monet Services, you consent to our data practices as described in this Privacy Policy, including the collection, use, and/or 
          disclosure of Personal Data provided by or on behalf of you and/or relating to you by us and our service providers and agents described herein. If you do not agree with, consent to, or are 
          not comfortable with any aspect of this Privacy Policy, you should immediately stop accessing and/or using Monet Services. <br/><br/><br/>We reserve the right to modify this Privacy Policy 
          at any time and/or when required by law. We will notify Users of any changes to this Privacy Policy by email (sent to the email address as specified in your Account) and/or by means of a 
          notice published on the Platform. Such modifications will be effective and binding on you upon posting such modifications onto the Platform or such other timing as Monet may specify in 
          writing. You acknowledge that it is your responsibility to check for notifications regularly and the updated Privacy Policy upon such notifications. You further agree that any notification
          of modifications in the manner as aforesaid shall be sufficient notice to you and your continued access to and/or use of the Monet Services following the notification of any modifications
            to this Privacy Policy indicates your acceptance of any such modifications.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Definitions</h2>
        <div className="m-10 text-white">Capitalized terms used in this Privacy Policy have the following meanings, and any capitalized terms not defined in this Privacy Policy shall be as defined in the {termsnCond()}:
        “Personal Data” Personal Data also means any information that directly or indirectly can be used to identify a natural person, such as a name, an identification number, location data, an online identifier, or to
          one or more factors specific to the physical, economic, cultural or social identity of you as a natural person.</div>
        <h2 className="text-xl m-10 pt-10 text-white">Collection of personal data</h2>
        <p className="m-10 text-white">Monet wants you to understand the types of information Monet collects when you register for an Account and/or access and/or use Monet’s Platform. You decide whether
          or not to provide us with the information, however, we may not be able to offer you some or all of our Services if you choose not to share certain information with us.<br/><br/><br/>Personal 
          Data and information we may collect from or in relation to you include but are not limited to the following:<br/><br/><br/>(i) Identification Information - full name, home address, email address,
          date of birth, age, nationality, gender, signature, photographs, tax identification number, passport number, driver's license details, national identity card details, immigration visa information;<br/><br/><br/>(ii) 
          Account Information - username, password, account settings, and preferences;<br/><br/><br/>(iii) Financial Information - bank account numbers, bank statements, virtual currency wallet addresses, trading data;<br/><br/><br/>(iv) 
          Verification Information - utility bills, phone bills, bank statements, tax returns;<br/><br/><br/>(v) Information relating to the usage of our Services - device identification, operating system, browser type, IP address, domain
            name, access date and time via server logs; and<br/><br/><br/>(vi) Transaction Information - trading activity, order activity, deposits, withdrawals, and account balances.</p>
        <h2 className="text-xl m-10 pt-10 text-white">How we use your personal information</h2>
        <p className="m-10 text-white">We and our service providers and agents may collect, use, process, disclose and/or share Personal Data we collect from or in relation to you for purposes including but not limited to the following: (i) to communicate with you (e.g. responding to queries, sending you newsletters, communicating with you in relation to other products or services offered by us and/or our partners); (ii) to administer, deliver, develop, improve and personalize Monet Services; (iii) operate and manage Monet Services; (iv) administering events and activities (e.g. extending and processing invitations); (iv) for loss prevention and anti-fraud purposes; (v) to generate data from any Personal Data we collect from you or in relation to you for market analysis, research and survey and/or research and development purposes; (vi) to fulfil, protect and enforce our contractual and legal rights and obligations; and (vii) to comply with applicable laws, regulations and other requirements (e.g. providing assistance to law enforcement agencies, regulatory authorities and other governmental agencies; performing internal audits). Some of the foregoing purposes are further elaborated below.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Legal and Compliance Regulations</h2>
        <p className="m-10 text-white">We are required by law to collect and use your Personal Data in connection with our Services - E.g. we need to identify and verify customers using our Services in order to comply with anti-money laundering and terrorist financing laws across different jurisdictions. In addition, we use third parties to verify your identity by comparing the Personal Data you provided against third-party databases and public records. If you do not provide this Personal Data, you will not be allowed to use our Services.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Enforcement of our Terms and Conditions</h2>
        <div className="m-10 text-white">Due to the nature of the sensitive information we handle, it is important for us and for the benefit of our customers that we are actively monitoring, investigating, preventing, and mitigating any potentially prohibited or illegal activities, enforcing our agreements with third parties, and/or violations of our {termsnCond()} or agreements related to the Services. We may use any of your Personal Data collected in connection with our Services for these purposes. The consequences of not being able to process your Personal Data for such purposes is the termination of your Account as we cannot perform our Services in accordance with the {termsnCond()}.</div>
        <h2 className="text-xl m-10 pt-10 text-white">Performance of our Obligations and Provision of our Services</h2>
        <div className="m-10 text-white">We process your Personal Data in order to provide the Services to you. For example, when you want to buy an art token, we require certain information such as your identification, contact information, and payment information. We cannot provide you with Services without such information. The consequences of not being able to process your Personal Data for such purposes is the termination of your Account as we cannot perform our Services in accordance with the {termsnCond()}.</div>
        <p className="m-10 text-white">We send administrative or account-related information to you to keep you updated about our Services, inform you of relevant security issues or updates, or provide other transaction-related information.
          Without such communications, you may not be aware of important developments relating to your Account that may affect how you can use our Services.<br/><br/><br/>We process your Personal Data to provide a personalized experience and 
          implement the preferences you request. Without such processing, we may not be able to ensure your continued enjoyment of part or all of our Services.<br/><br/><br/>We process your Personal Data when you contact us to resolve any questions,
          or disputes, collect fees or troubleshoot problems. We may process your information in response to another customer’s request, as relevant. Without processing your Personal Data for such purposes, we cannot respond to your requests and 
          ensure your uninterrupted use of the Services.<br/><br/><br/>We process your Personal Data for quality control and staff training to make sure we continue to provide you with accurate information. If we do not process Personal Data for 
          quality control purposes, you may experience issues with the Services such as inaccurate transaction records or other interruptions. Our basis for such processing is based on the necessity of performing our contractual obligations with you.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Network and Information Security</h2>
        <p className="m-10 text-white">We process your Personal Data in order to enhance security, monitor and verify identity or service access, combat spam or other malware or security risks, and to comply with applicable securities laws and regulations. The threat landscape on the internet is constantly evolving, which makes it more important than ever that we have accurate and up-to-date information about your use of our Services. Without processing your Personal Data, we may not be able to ensure the security of our Services.</p>
        <h2 className="text-xl m-10 pt-10 text-white">To further improve our Services</h2>
        <p className="m-10 text-white">We process your Personal Data to better understand the way you use and interact with our Services. In addition, we use such information to customize, measure, and improve our Services and the content and layout of the Website and other Monet Services, and to develop new services. Without such processing, we cannot ensure your continued enjoyment of our Services.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Marketing and Communications</h2>
        <p className="m-10 text-white">Based on your communication preferences, we may send you marketing communications to inform you about our events or our partner events; to deliver targeted marketing; and to provide you with promotional offers based on your communication preferences. We use information about your usage of our Services and your contact information to provide marketing communications. We may also share your Personal Data with third parties to help us with our marketing and promotional projects or sending marketing communications.
         You may opt-out of our marketing activities by contacting us at julianomonet@gmail.com</p>
        <h2 className="text-xl m-10 pt-10 text-white">Third-party information we receive</h2>
        <p className="m-10 text-white">As required and/or permitted by applicable law, we may obtain information about you from third-party sources, such as public databases, credit bureaus, and identification verification partners for purposes of verifying your identity. E.g. Identification verification partners use a combination of government records and publicly available information about you to verify your identity. Such information includes your name, address, job role, public employment profile, credit history, status on any sanctions lists maintained by public authorities, and other relevant data.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Personal data we may share with other parties</h2>
        <p className="m-10 text-white">We take care to allow Personal Data we collect from or in relation to you to be accessed only by those who really need to in order to perform their tasks and duties and to share with third parties who have 
        a legitimate purpose for accessing it. We will generally only share Personal Data we collect from or in relation to you in the following circumstances:<br/><br/><br/>(i) We share your Personal Data with third-party identity verification 
        services and transaction monitoring services to prevent fraud and other illegal activities. This allows us to confirm your identity by comparing the information you provide us to public records and other third-party databases. These service 
        providers may create derivative data based on your Personal Data that can be used solely in connection with the provision of identity verification and fraud prevention services.<br/><br/><br/>(ii) We share your Personal Data with financial 
        institutions and payment services providers with which we partner to process payments you have authorized.<br/><br/><br/>(iii) We share your Personal Data with service providers under contract who help with parts of our business operations 
        such as payment, marketing, and technology services. Our contracts provide for binding enforceable requirements on our service providers in compliance with applicable laws.<br/><br/><br/>(iv) We may share Personal Data we collect from or in
          relation to you with companies or other entities that purchase our assets pursuant to a court-approved sale under applicable bankruptcy laws and/or where we are required to share what we collect from or in relation to you pursuant to
          applicable insolvency law.</p>
        <div className="m-10 text-white">(v) We may share Personal Data we collect from or in relation to you with law enforcement agencies, regulatory authorized or other governmental authorities, officials, or other third parties when we are compelled to do so by a subpoena, court order, or similar legal procedure, or when we believe in good faith that the disclosure of Personal Data we collect from or in relation to you is necessary to prevent physical harm or financial loss, to report suspected illegal activity or to investigate violations of our {termsnCond()} or any other applicable rules, policies or procedures.</div>
        <p className="m-10 text-white">(vi) We may share Personal Data we collect from or in relation to you with companies or other entities that we plan to merge with or be acquired by. Should such a combination occur, we will require that the new
          combined entity follow this Privacy Policy with respect to Personal Data we collect from or in relation to you. You will receive prior notice of any change in applicable policies, including in the manner as set out in this Privacy Policy.<br/><br/><br/>(vii) We may store and process Personal Data we collect from or in relation to you in our facilities in various locations, including Delaware. This Privacy Policy applies regardless of the location in which we store and process your Personal Data.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Information security</h2>
        <p className="m-10 text-white">We use our best endeavors to ensure the confidentiality and protection of the Personal Data we collect from or in relation to you, however, we cannot guarantee absolute security. We work hard to protect
          Personal Data we collect from or in relation to you from unauthorized access, alteration, modification, collection, use, disclosure, copying, disposal, destruction, or similar risks. E.g. we use data encryption and firewalls and we
          restrict access to your Personal Data on a need-to-know basis for our employees, contractors, and agents who require the information to fulfill their job responsibilities.<br/><br/><br/>Further, we cannot ensure the security or
          confidentiality of the Personal Data that you transmit to us or receive from us via the internet or wireless connection. If you have reason to believe that your data is no longer secure, or if you become aware of any unauthorized access to or use of your account, please contact us at the email address julianomonet@gmail.com</p>
        <h2 className="text-xl m-10 pt-10 text-white">Retention of personal data</h2>
        <p className="m-10 text-white">We take steps to ensure that we retain Personal Data no longer than is necessary for the fulfillment of the purpose for which it was collected unless the further retention of such Personal Data is otherwise permitted or required by applicable laws and regulations.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Under-age personal data</h2>
        <p className="m-10 text-white">We do not knowingly request to collect Personal Data from any person under the age of 18. If a User submitting Personal Data is suspected of being younger than 18 years of age, we will require the User to close his or her account and will not allow the User to continue buying or selling digital currencies. We will also take steps to delete the Personal Data we collect from or in relation to such User as soon as possible. Please notify us if you know of any individuals under the age of 18 using our Services so we can take action to prevent access to our Services.</p>
        <h2 className="text-xl m-10 pt-10 text-white">European Economic Area privacy statement</h2>
        <p className="m-10 text-white">While customers and users who are located in the EEA are customers of our Delaware entity, we recognize and, to the extent applicable to us, adhere to relevant EEA data protection laws.
        We may collect Personal Data from Users located in the EEA. To facilitate the services we provide to Users located in the EEA, we request explicit consent for the transfer of Personal Data from the EEA to Delaware. If you are an individual located in the EEA and you decline to consent to such transfer, you will no longer be able to use Monet Services and our Services. You will have the ability to withdraw art tokens from your Account; however, all other functionality may be partially restricted or completely disabled depending on your jurisdiction.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Lawful Grounds to Process and Obtain Consent</h2>
        <p className="m-10 text-white">We process the Personal Data of users who are located in the EEA for one or more of several lawful purposes, including:<br/><br/><br/>(i) To provide you with our Services, including customer service
          support;<br/><br/><br/>(ii) To optimize and enhance our Services for all customers or for you specifically;<br/><br/><br/>(iii) To comply with legal obligations, including to conduct anti-fraud and identity verification and authentication
          checks (you authorize us to share your Personal Data with our third-party service providers, who may also conduct their own searches of publicly available Personal Data about you);<br/><br/><br/>(iv) To monitor the usage of our Services, 
          conduct automated and manual security checks of our service, to protect our rights, and perform our lawful obligations.<br/><br/><br/>Users in the EEA may withdraw consent at any time where consent is the lawful basis for processing their Personal Data. Should a user withdraw consent for processing or otherwise object to processing that impedes our ability to comply with applicable laws and regulations, a user may be unable to avail him or herself of the Services we provide.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Non-Disclosure of Personal Data</h2>
        <p className="m-10 text-white">Our employees are prohibited, either during or after their employment, from disclosing Personal Data to any person or entity outside of our company, including family members, except under the circumstances described above. An employee is only permitted to disclose the Personal Data of a user to such other employees who need access to such information in order to deliver our services to that user.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Third-party personal data</h2>
        <p className="m-10 text-white">Where you disclose the Personal Data of other individuals to us, you warrant that such individuals have consented to us collecting, processing, using, disclosing, and/or sharing such Personal Data for: (i) the relevant purpose for which you made the disclosure or as was notified to you at the relevant time; and (ii) the other purposes as described below in this Privacy Policy.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Cookies</h2>
        <p className="m-10 text-white">We and our vendors and service providers use cookies and other similar technologies (e.g., web beacons, flash cookies, etc.) (“Cookies”) to automatically collect information, measure and analyze which web pages 
        you click on and how you use the Monet Platform, enhance your experience using the Platform, improve our services, and provide you with targeted advertising on the Monet Platform and elsewhere across your devices. Cookies enable the Monet 
        Platform to provide certain features and functionality. Web beacons are very small images or small pieces of data embedded in images, also known as “pixel tags” or “clear GIFs,” that can recognize Cookies, the time and date a page is viewed
          a description of the page where the pixel tag is placed, and similar information from your computer or device. By using the Monet Platform, you consent to our use of Cookies. Additionally, we allow our business partners, advertising networks, 
          and other advertising vendors and service providers (including analytics vendors and service providers) to collect information about your online activities through Cookies. We link your contact or subscriber information with your activity on
          our Platform across your devices, using your email or other log-in or device information. These third parties may use this information to display advertisements on our Platform and elsewhere online tailored to your interests, preferences,
            and characteristics. We are not responsible for the privacy practices of these third parties, and the information practices of these third parties are not covered by this Privacy Policy.<br/><br/><br/>You may be able to refuse or disable 
            Cookies by adjusting your browser settings. Because each browser is different, please consult the instructions provided by your browser. Please note that you may need to take additional steps to refuse or disable certain types of Cookies.
            For example, due to differences in how browsers and mobile apps function, you may need to take different steps to opt out of Cookies used for targeted advertising in a browser and to opt-out of targeted advertising for a mobile application,
              which you may control through your device settings or mobile app permissions. In addition, your opt-out selection is specific to the particular browser or device that you are using when you opt out, so you may need to opt-out separately
              for each browser or device. If you choose to refuse, disable, or delete Cookies, some of the functionality of the Monet Platform may no longer be or become partially available to you.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Questions</h2>
        <p className="m-10 text-white">If you have questions or concerns regarding this Privacy Policy, please feel free to email us at julianomonet@gmail.com. This Privacy Policy only pertains to our policies and practices with regard to your Personal Information. Websites linked to and/or from our website are not covered by this Privacy Policy.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Headings and Interpretation</h2>
        <p className="m-10 text-white">The headings and subheadings herein are included for convenience and identification only and are not intended to interpret, define or limit the scope, extent, or intent of this Privacy Policy in any manner whatsoever. The meaning of general words is not limited by specific examples introduced by expressions such as, “including”, “for example”, “such as”, or such similar expressions, and the word “includes” or “including” as used in this Privacy Policy shall be construed to mean “includes without limitation” or, as the case may be, “including without limitation”.</p>
      </div>
    </div>
  );
}

export default PolicyText;