import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Link from 'next/link';
import { getAuth } from "firebase/auth";

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeLinecap="round"
    strokeWidth="3"
    {...props}
  />
);

const OpenPath = (props) => (
  <motion.nav
    key="openModal"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    {...props}
  />
);

const transition = { duration: 0.33 };

function MenuToggle({ toggle, isOpen }) {
  return (
    <div className="z-10 fixed cursor-pointer pr-6 " onClick={toggle}>
      <svg width="23" height="23" viewBox="0 0 23 23">
        <Path
          animate={isOpen ? "open" : "closed"}
          initial={false}
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5", stroke: "hsl(0, 0%, 100%)" },
            open: { d: "M 3 16.5 L 17 2.5", stroke: "hsl(0, 0%, 100%)" },
          }}
          transition={transition}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          stroke="hsl(0, 0%, 100%)"
          animate={isOpen ? "open" : "closed"}
          initial={false}
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={transition}
        />
        <Path
          animate={isOpen ? "open" : "closed"}
          initial={false}
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346", stroke: "hsl(0, 0%, 100%)" },
            open: { d: "M 3 2.5 L 17 16.346", stroke: "hsl(0, 0%, 100%)" },
          }}
          transition={transition}
        />
      </svg>
    </div>
  );
}

export default function MobileNavLinks() {
  const [isOpen, setOpen] = useState(false);
  const fadeTransition = { duration: 0.5 };
  const user = getAuth().currentUser;
  const [spacing, setSpacing] = useState('ml-20');
  useEffect(() => {
    if (!user){
      setSpacing("ml-28");
    }
  }, []);

  function OpenBurger() { 
    return (
      <div className="space-y-10 right-20 absolute flex flex-col h-max w-screen pt-64 mt-96 pb-96">
        <OpenPath animate={isOpen ? "open" : "closed"} transition={fadeTransition} className={`mt-20 ${spacing} w-screen pt-96`}>
        <div className="bg-black">
          <div className="pl-8 mt-96 text-lg pt-8 pb-7 text-white p-1 w-full bg-black">
            <Link href="/" className="hover:text-xl hover:font-semibold">Home</Link>
          </div>
          <div className="pl-8 pb-7 text-lg text-green-400 p-1 w-full bg-black">
            <Link href="/pricing" className="hover:font-semibold hover:text-xl">Pricing</Link>
          </div>
          <div className="pl-8 pb-7 text-lg text-white p-1 w-full bg-black">
            <Link href="/category" className="hover:font-semibold hover:text-xl">Explore</Link>
          </div>
          <div className="pl-8 text-lg text-white pb-10 p-1 w-full bg-black">
            <Link prefetch={false} href="/support" className="hover:font-semibold hover:text-xl">Support</Link>
          </div>
          <div className="pl-8 pb-7 text-lg text-white p-1 w-full bg-black">
            {/* <div className="hover:font-semibold hover:text-xl">{" "}</div> */}
            <Link href="/about" className="hover:font-semibold hover:text-xl">About</Link>
          </div>
          <div className="w-screen h-96 bg-black" />
          <div className="w-screen h-96 bg-black" />
        </div>
        </OpenPath>
      </div>
    )
  }

  return (
    <div className="overflow-hidden flex justify-end items-center h-full pt-3">
      <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
      <AnimatePresence>{isOpen && (<OpenBurger />)}</AnimatePresence>
    </div>
  );
}