import React, { useState } from 'react';
import { submitComment } from '../services';
import { auth } from '../firebase/firebaseApp';
import { useAuthState} from 'react-firebase-hooks/auth';

const CommentsForm = ({ slug }) => {
  // autheticating user 
  const [user, loading] = useAuthState(auth);
  // comment variables
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', comment: ''});

  const onInputChange = (e) => {
    const { target } = e;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handlePostSubmission = () => {
    setError(false);
    if (!user){
      setError(true);
      setErrorMessage("⛔ Sign in to continue");
      return;
    }
    formData.email = user.email
    const { name, email, comment } = formData;
    if (!comment || !name || !email) {
      setError(true);
      return;
    }
    if (comment.length < 3) {
      setError(true);
      setErrorMessage("⚠️ Comment is too short");
      return;
    }

    const commentObj = {
      name,
      email,
      comment,
      slug,
    };

    submitComment(commentObj)
      .then((res) => {
        if (res.createComment) {
          formData.comment = '';
          setFormData((prevState) => ({
            ...prevState,
            ...formData,
          }));
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 5000);
        }
      });
  };

  return (
    <div className="bg-black shadow-lg rounded-lg p-8 pb-12 mb-8">
      <h3 className="text-xl mb-8 text-blue-100 font-semibold border-b pb-4">Leave a Reply</h3>
      <div className="grid grid-cols-1 gap-4 mb-4">
        <textarea value={formData.comment} onChange={onInputChange} className="p-4 outline-none w-full rounded-lg h-40 focus:ring-2 focus:ring-blue-400 bg-gray-900 text-gray-200" name="comment" placeholder="Comment" />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        <input type="text" value={formData.name} onChange={onInputChange} className="py-2 px-4 outline-none w-full rounded-lg focus:ring-2 focus:ring-blue-400 bg-gray-900 text-gray-300" placeholder="Name" name="name" />
        <button type="button" onClick={handlePostSubmission} className="transition duration-500 ease hover:bg-blue-600 bg-blue-900 text-lg font-medium rounded-lg text-gray-50 py-3 cursor-pointer">Comment</button>
      </div>
      {error && <div className="text-medium font-medium text-red-500">{errorMessage}</div>}
      <div className="mt-8 flex justify-center items-center">
        {showSuccessMessage && <span className="text-xl float-right font-semibold mt-3 text-green-500">Comment submitted for review 😁</span>}
      </div>
    </div>
  );
};

export default CommentsForm;
