import React, { useCallback, useEffect, useRef, useState } from "react";
import Image from "next/image";
import { off, onChildAdded, ref } from "firebase/database";
import { database, updateData } from "../../firebase/firebaseApp";
import { v4 as uuidv4 } from 'uuid';
import Link from "next/link";
import TimeConverter, { convertTime } from "../TimeConverter";
import { toast } from "react-toastify";
import { deleteObject, getDownloadURL, getStorage, ref as sRef, uploadBytes } from 'firebase/storage'

const ShowMessages = ({user, userID, showUser, myMessages}) => {
  const [message, setMessage] = useState("");

  const messageListRef = useRef([]);
  const [prevUser, setNextUser] = useState(showUser);
  const [myMessageList, setMessageList] = useState(messageListRef.current);
  const messageListScroll = useRef(null); //to scroll to the most recent messages on load

  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  const refContainer = useRef('');

  if(!user){
    return(
      <Loading />
    )
  }

  const scrollToLastMessage = () => {
    if (messageListScroll.current) {
      messageListScroll.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  const addImageToPost = (e) => {
    const reader = new FileReader()
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0])
      setFile(e.target.files[0]);
    }
    reader.onload = (readerEvent) => {
      setSelectedFile(readerEvent.target.result)
    }
  }

  const handleNewMessage = useCallback((data) => {
    if (data.exists()) {
      const newMessage = data.val()
      // console.log("MESSS", newMessage, data.val())
      if (newMessage.createdAt && !JSON.stringify(myMessageList).includes(JSON.stringify(newMessage))) {
        messageListRef.current.push(newMessage);
        setMessageList([...messageListRef.current]);
      }
    }
  }, [messageListRef.current, showUser.receiver]);

  useEffect(() => {
    scrollToLastMessage();
    if(showUser.receiver){
      if(showUser.userInd !== prevUser && showUser.receiver.userData.messages){
        setNextUser(showUser.userInd)
        messageListRef.current = showUser.receiver.userData.messages[userID] ? Object.values(showUser.receiver.userData.messages[userID]) : [];
        setMessageList(messageListRef.current);
      }else{
        const messagesRef = ref(database, '/user_profile/' + showUser.receiver.uid + '/messages/' + userID);
        onChildAdded(messagesRef, handleNewMessage); 
        // updates the message feed depending on receiver
        return () => {
          off(messagesRef, 'child_added', handleNewMessage);
        };
      }

    // listen for new messages added to the Firebase Realtime Database
    }
  }, [showUser.userInd, handleNewMessage]);


  if(showUser.receiver && Object.values(myMessages) !== []){
    // to remove the boolean representing the notification
    var { unread = false, ...messagesToDisplay } = Object.values(myMessages)[showUser.userInd] || [];
    if(!Array.isArray(messagesToDisplay)){
      messagesToDisplay = Object.values(messagesToDisplay);
    }
    // to remove the boolean representing the notification
    const filteredMessageList = myMessageList.filter(el => typeof el !== "boolean");
    const allmess = [...messagesToDisplay, ...filteredMessageList];

    allmess.sort((a, b) => {
      const dateA = Date.parse(a.createdAt || a.dateTime);
      const dateB = Date.parse(b.createdAt || b.dateTime);
      return dateA - dateB;
      // return (b.createdAt || a.dateTime).localeCompare(a.createdAt || b.dateTime)
    });

    const sendMessage = async (e) => {
      e.preventDefault();
      if (!user) {
      return toast.error("Sign in to continue", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      }
      if (message.length < 2 && !file) {
        return toast.error("Add content to Post 😊", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      }
      const messageID = uuidv4();
      const storage = getStorage();
      const profilePicRef = sRef(storage, `userPosts/${user.uid}/messages/${messageID}`);
      if(file) {
        // Make sure the file is smaller than 3MB
        if (file.size > 3000000) {
          setSelectedFile(null);
          setFile(null);
          return toast.error("Photo quality is too high 🤔", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        // upload Image to Firebase Storage db to match user-uid
        await uploadBytes(profilePicRef, file).then((snapshot) => {
          console.log('Uploaded a blob or file!', snapshot);
        });
        await getDownloadURL(profilePicRef).then((url) => {
          refContainer.current = url;
        }).catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              console.log("storage/object-not-found");
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              console.log("storage/unauthorized");
              break;
            case 'storage/canceled':
              // User canceled the upload
              console.log("storage/canceled");
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              console.log("storage/unknown");
              break;
          }
        });
      }
  
      const dateSent = String(new Date());
      const updates = {};
      const newMessage = {
        text: message,
        imageURL: refContainer.current,
        uid: user.uid,
        profileURL: user.photoURL,
        messageID: messageID,
        createdAt: dateSent,
      };   

      updates['/user_profile/' + showUser.receiver.uid + "/messages/" + userID + "/unread" ] = true;
      updates['/user_profile/' + showUser.receiver.uid + "/messages/" + userID + "/" + messageID ] = newMessage;
      // Add data to firebase RealTime Database
      updateData(updates);
      
      setMessage("");
      setFile(null);
      setSelectedFile(null);
  }

    return(
      <div className="">
        <div name="Header" className="flex items-center justify-start border-b h-20">
          <Link href={`/Profile/${showUser.receiver.uid}`} className="flex items-center justify-start">
            <div className="relative object-cover h-14 w-14">
              <Image
                sizes="(max-width: 768px) 100vw,
                (max-width: 1200px) 50vw,
                33vw"
                unoptimized
                src={showUser.receiver.userData.profileURL}
                fill
                className="object-cover rounded-full object-center"
                alt="profile picture Monet friend"
              />
            </div>
            <div className="flex items-center ">
              <div className="h-1/2 pl-3 text-xl">{showUser.receiver.userData.username}</div>
              <div className="h-1/2 pl-3 text-blue-400 text-xl">{showUser.receiver.userData.job_position}</div>
            </div>
          </Link>
        </div>
        <div name="Messages" className="py-5 h-[30rem] overflow-auto">
          {allmess.map((message, index) => {
            var bg = "bg-gray-800 text-left";
            var position = "justify-start";
            var timePosition = "text-start pl-3"
            if(message.uid === user.uid){
              bg="bg-gradient-to-b from-blue-500 via-blue-600 to-blue-700"
              position = "justify-end"
              timePosition = "text-end pr-3"
            }
            return (
              <div key={index} className={`flex mx-2 py-1 ${position}`}>
                <div className="flex flex-col">
                  {index >= 1 ? (allmess[index-1].uid !== allmess[index].uid || convertTime(allmess[index-1].createdAt) !== convertTime(message.createdAt) )&& <label className={`${timePosition} pb-1 text-xs opacity-50 font-medium text-gray-300`}><TimeConverter date={message.createdAt} /></label> :
                  <label className={`${timePosition} pb-1 text-xs opacity-100 font-medium text-red-300`}><TimeConverter date={message.createdAt} /></label>}
                  {message.imageURL !== "" && <div className="inline-block rounded-lg mb-3 relative h-96 w-96 px-3 py-1">
                    <Image
                      src={message.imageURL}
                      fill 
                      sizes="(max-width: 768px) 100vw, 
                      (max-width: 1200px) 50vw,
                      33vw"
                      unoptimized
                      className="inline-block"
                      alt="profile picture rounded-lg Monet friend"
                    />
                  </div>}
                  {message.text !== "" && <div className={`max-w-md inline-block flex ${position}`}><div className={`px-3 py-1 max-w-md inline-block whitespace-normal break-words rounded-xl text-lg ${bg}`}>{message.text}</div></div>}
                </div>
              </div>
            )
          })}
          <div className="flex justify-end mt-5 w-full">
            {selectedFile && (
              <div className="relative mb-4">
                <div className='absolute z-20 w-8 h-8 bg-[#15181c] hover:[#272c26] text-red-400 bg-opacity-75 rounded-full flex items-center justify-center top-7 left-2 cursor-pointer' onClick={() => setSelectedFile(null)}>X</div>
                <div className="rounded-xl relative h-80 w-96">
                  <Image
                    src={selectedFile}
                    alt="MonetUser Post Image Art"
                    className='rounded-2xl max-h-80 object-contain' 
                    fill
                  />
                </div>
                <div className="text-white text-centeropacity-50 text-lg pt-2">Add comment or Send</div>
              </div>
            )}
          </div>
          <div ref={messageListScroll}></div>
        </div>
        <form onSubmit={sendMessage} className="w-full flex flex-col items-center justify-center">
          <div className="flex w-full items-center">
            <div className='flex gap-4 text-[20px] text-[#1d9bf0]'>
              <label htmlFor="file" className="px-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="text-white" viewBox="0 0 16 16"> <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/> <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/> </svg>
              </label>
              <input id="file" type="file"
                hidden
                onChange={addImageToPost}
              />
            </div>
            <input name="comment" value={message} onChange={(e) => setMessage(e.target.value)} type="text" className="bg-gray-900 w-full rounded-lg focus:outline-none text-xl text-blue-400 px-3 py-3" placeholder="Monet Message"/>
            <button disabled={!message && !selectedFile} type="submit" className="disabled:opacity-50 disabled:pointer-events-none text-xl font-semibold px-3 py-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="disabled:text-white text-blue-400" viewBox="0 0 16 16"> <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"></path> </svg>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ShowMessages;
