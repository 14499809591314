import { now } from "moment";
import { FormEvent, useState } from "react";
import { BankForm } from "./BankForm";
import { BusinessForm } from "./BusinessForm";
import { CompletedForm } from "./CompletedForm";
import { useMultiStepForm } from "./formHelp";
import { UserForm } from "./UserForm";
// import { UploadImageDB } from "./UploadImageDB";
import { sendContactForm } from "../../../lib/api";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { updateData } from "../../../firebase/firebaseApp";

type FormData = {
  firstName: string,
  middleName: string,
  lastName: string,
  
  businessCountry: string,
  businessType: string,
  BusinessName: string,
  terms: boolean,

  citizenship: string,
  birthCountry: string,
  identityProofType: boolean,
  countryOfIssue: string,
  identityNumber: string,
  expiryDate: Date,
  OTP: string,
  ZIP: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  number: string,
  SMS_Verification_Language: string,

  financialInstitutionName: string, //theres a list of all acceptable banks in the world, in drive
  BankCountry: string,

  // US Bank
    NineDigitRoutingNumber: string,
  // UK Bank
    bankSortCode: string,
    federalTaxID: string,
    stateTaxID: string,
  // Canada Bank
    accountTypeCanada: string, //savings or checking
    institutionNumber: string,
    transitNumber: string,
  // Australia, New Zealand Bank
    BSB: string,
  // China Bank 
    isBusinessBankAccount: boolean, 
    // ********* start ********
    //********* if isBusinessBankAccount === false ************
    nationalIDNumber: string,
    //******** if isBusinessBankAccount === true ************
    businessLicenseNumber: string,
    // ********* end ********
    bankName: string,
    branchName: string,
    bankCity: string,
    bankProvince: string,
    accountHolderName: string,
    isBankAccountNumber: boolean, 
    // if isBankAccountNumber === false its a debit card number
    debitCardNumber: string,
    // 
  // Hong Kong, Singapore Bank
    clearingCode: string, // also called bankCode
    branchCode: string,
  // India Bank
    accountTypeIndia: string, //savings, overdraft, or current 
    IFSC: string,
  // Morocco Bank
    BICMorocco: string, //also called BIC
  // Philippines Bank
    BRSTN: string,

  // every country above needs a Bank account number, except the ones below
    bankAccountNumber: string,
  // Belgium, Bulgaria, Croatia, Cyprus, Czech Rep, Denmark, Estonia, Finland, France
  // Germany, Gibraltar, Greece, Hungary, Ireland, Italy, Latvia, Liechtenstein,
  // Lithuania, Luxembourg, Malta, Monaco, Netherlands, Norway, Poland, Portugal , Romania,
  // San Marino, Slovakia, Slovenia, Spain, Switzerland,Bank
    BIC: string,
    IBAN: string,
  // everyone else goes through hyperwallet

  // I need credit card statements or bank statements
  statements: any,
  IDPass: any,
  facePhoto: any,

  error: string,
}
const initial_data: FormData = {
  firstName: "",
  middleName: "",
  lastName: "",

  businessCountry: "",
  businessType: "",
  BusinessName: "",
  terms: false,

  citizenship: "",
  birthCountry: "",
  identityProofType: false,
  countryOfIssue: "",
  identityNumber: "",
  expiryDate: new Date(now()),
  OTP: "",
  ZIP: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  number: "",
  SMS_Verification_Language: "",

  financialInstitutionName: "",
  BankCountry: "",

  // US Bank
  NineDigitRoutingNumber: "",
  // UK Bank
  bankSortCode: "",
  federalTaxID: "",
  stateTaxID: "",
  // Canada Bank
  accountTypeCanada: "",
  institutionNumber: "",
  transitNumber: "",
  // Australia, New Zealand Bank
  BSB: "",
  // China Bank 
  isBusinessBankAccount: false,
  // ********* start ********
  //********* if isBusinessBankAccount === false ************
  nationalIDNumber: "",
  //******** if isBusinessBankAccount === true ************
  businessLicenseNumber: "",
  // ********* end *******
  bankName: "",
  branchName: "",
  bankCity: "",
  bankProvince: "",
  accountHolderName: "",
  isBankAccountNumber: false,
  // if isBankAccountNumber === false its a debit card number
  debitCardNumber: "", 
  // Hong Kong, Singapore Bank
  clearingCode: "",
  branchCode: "",
  // India Bank
  accountTypeIndia: "",
  IFSC: "",
  // Morocco Bank
  BICMorocco: "",

  // Philippines Bank
  BRSTN: "",

  // every country above needs a Bank account number, except the ones below
  bankAccountNumber: "",
  // Belgium, Bulgaria, Croatia, Cyprus, Czech Rep, Denmark, Estonia, Finland, France
  // Germany, Gibraltar, Greece, Hungary, Ireland, Italy, Latvia, Liechtenstein,
  // Lithuania, Luxembourg, Malta, Monaco, Netherlands, Norway, Poland, Portugal , Romania,
  // San Marino, Slovakia, Slovenia, Spain, Switzerland,Bank
  BIC: "",
  IBAN: "",
  // everyone else goes through hyperwallet
  // I need credit card statements or bank statements
  statements: undefined,
  IDPass: undefined,
  facePhoto: undefined,

  error: "",
}

// {size:0, type: "", lastModified: 0, name: "", webkitRelativePath: "", slice: ( contentType?: string | undefined): new Blob, stream: "", Text, prototype},
function BusForm(user: any) {
  const [data, setData] = useState(initial_data);
  const [error, setError] = useState('');
  const storage = getStorage();
  var country_list = ["unitedkingdom","unitedstates","unitedstatesofamerica", "unitedarabemirates","australia","austria", 
  "beligium", "bulgaria", "canada", "china", "czechrepublic", "denmark", "estonia", "finland", "france", "germany", "greece","hongkong",
  "hungary", "ireland", "italy", "japan", "latvia", "lebanon", "lithuania", "luxembourg", "malta", "mexico", "netherlands", "newzealand", "norway",
  "poland", "portugal", "qatar", "romania", "singapore", "slovakia", "slovenia", "spain", "sweden", "switzerland"];
  function updateFields(fields: Partial<FormData>){
    setData(prev => {
      return {...prev, ...fields}
    })
  }
  const {steps, curIndex, step, isFirstStep, backForm, nextForm, isLastStep} = useMultiStepForm(
    [<UserForm {...data} updateFields={updateFields}/>, <BusinessForm {...data} updateFields={updateFields} />, 
     <BankForm {...data} updateFields={updateFields}/>, <CompletedForm />
  ]);
  // it's not updating the fields/ deleting the entries with wrong values.
  async function onSubmit(e: FormEvent){
    e.preventDefault();
    if(data.error !== ""){
      setError(data.error);
      console.log("huge error");
      return
    } if(curIndex === 0 && !isLastStep){
      setError("");
      return nextForm();
    } if (curIndex === 1 && !isLastStep){
      if(data.businessType === "Select a Business Type" || data.businessType === ""){
        setError("Please select a business type");
        setData(prev => {
          prev.businessType= "";
          return {...prev}
        })
        return
      } else if(country_list.includes(data.citizenship.replace(/ /g,"").toLowerCase()) === false){
        setError("Citizenship has an invalid character or we are not accepting sellers from your country at the moment");
        setData(prev => {
          prev.citizenship= "";
          return {...prev}
        })
        return
      } else if(country_list.includes(data.birthCountry.replace(/ /g,"").toLowerCase()) === false){
        setError("Birth Country has an invalid character or we are not accepting sellers from your country at the moment");
        setData(prev => {
          prev.birthCountry= "";
          return {...prev}
        })
        return
      } else if(country_list.includes(data.countryOfIssue.replace(/ /g,"").toLowerCase()) === false){
        setError("Country of Issue has an invalid character or we are not accepting sellers from your country at the moment");
        setData(prev => {
          prev.countryOfIssue= "";
          return {...prev}
        })
        return
      } else if(data.terms === false){
        setError("Please Agree to the Terms to start Selling");
        setData(prev => {
          prev.terms= false;
          return {...prev}
        })
        return
      }
      setError("");
      return nextForm();
    } else if(curIndex === 2){
      const bankStatements = ref(storage, `userPosts/${user.user.uid}/sellerVer/BankStatements`);
      const identificationDocument = ref(storage, `userPosts/${user.user.uid}/sellerVer/IDPASS`);
      const facePicture = ref(storage, `userPosts/${user.user.uid}/sellerVer/face`);  
      if(country_list.includes(data.BankCountry.replace(/ /g,"").toLowerCase()) === false){
        setError("Bank Country has an invalid character or we are not accepting sellers from your country at the moment");
        setData(prev => {
          prev.BankCountry= "";
          return {...prev}
        })
        return
      } 
      else if(data.statements && data.IDPass && data.facePhoto) {
      // Make sure the file is smaller than 3MB
      if (data.statements.size > 3000000 && data.IDPass.size > 3000000 && data.facePhoto.size > 3000000) {
        setError("Photo quality is too high 🤔");
        return "failed";
      }
      // upload Image to Firebase Storage db to match user-uid
      await uploadBytes(bankStatements, data.statements).then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot);
      });
      await uploadBytes(identificationDocument, data.IDPass).then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot);
      });
      await uploadBytes(facePicture, data.facePhoto).then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot);
      });

      const updates: { [key: string]: object } = {};
      updates[`/user_profile/${user.user.uid}/sellerInfo/`] = data;
      // Add data to firebase RealTime Database
      updateData(updates);

      const message= `${user.user.displayName}'s Business Country: ${data.businessCountry},
       Business Type: ${data.businessType},
       Citizenship: ${data.citizenship},
       is pending Verification. Make sure to reply on its status before 24h`;
      //  send Email that email form is received or toast.
      const newVal =  { uid: user.user.uid, name: user.user.displayName, email: user.user.email, subject: "Seller Verification", message: message};
      await sendContactForm(newVal);
      setError("");
      return nextForm();
    }
    else if(!isLastStep) {
      setError("");
      return nextForm();
    }
  }
}

  return (
    <div className="w-full bg-black">
      <form onSubmit={onSubmit} className="border m-10 p-10">
        <div className="flex items-center justify-end">
          {curIndex + 1} / {steps.length}
        </div>
        {isLastStep ? null : <div className="flex items-start justify-start">
          {!isFirstStep && <button type="submit" onClick={backForm}>Back</button>}
        </div>}
        {step}
        <div className="">
          <div className="text-red-400 text-lg">
            {error}
          </div>
          <div className="flex items-center justify-center">
          {isLastStep ? null : <button className="" type="submit">{isLastStep ? "Finish" : "Next"}</button>}
          </div>
        </div>
      </form>
    </div>
  );
}

export default BusForm;