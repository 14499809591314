import { useState, ChangeEvent, useEffect } from "react";


type SellData = {
  artistName: string,
  Title: string,
  Medium: string,
  year: string,
  height: string,
  width: string,
  depth: string,
  provenance: string,
  artworkAddress: string,
  AdditionalInfo: string,
  photo1: any,
  photo2: any,
  photo3: any,
  errorform: string,
  reservePrice: string,
  hasFrame: boolean,
  rarity: string,
  acceptOffers: boolean,
  description: string,
}


type UserFormProps = SellData & {
  updateFields: (fields: Partial<SellData>) => void
}

type TextFieldProps = {
  description: string;
  updateDescription: (value: string) => void;
};

const TextField: React.FC<TextFieldProps> = ({ description, updateDescription }) => {
  const maxLength = 500;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value.slice(0, maxLength);
    updateDescription(inputValue);
  };

  return (
    <div className="pb-3">
      <textarea
        value={description}
        onChange={handleChange}
        rows={4}
        maxLength={maxLength}
        placeholder="Add details about condition, materials, meaning, shipping policies, etc"
        className="bg-gray-900 p-2 text-blue-300 text-sm rounded-md w-full"
      />
      <p className="text-end text-gray-300 text-xs">
        {description.length}/{maxLength}
      </p>
    </div>
  );
};

export function PhotoForm({photo1, photo2, photo3, description, acceptOffers, reservePrice, hasFrame, rarity, errorform, updateFields}: UserFormProps) {
  const [isToggled, setIsToggled] = useState(false);

  const handleDescriptionUpdate = (value: string) => {
    updateFields({ description: value });
  };

  const handleUpload = async (file: File, type: string) => {
    if(file === undefined){
      // error = "No Photo was Uploaded";
      updateFields({errorform: "No Photo was Uploaded"});
      console.log("error1", errorform);
      return null;
    }
    if (file.type.split("/")[0] !== "image" || file.type.split("/")[1] === "heic"){
      errorform = "Photo must be in the correct format(jpeg or png)";
      updateFields({errorform: "Photo must be in the correct format"});
      console.log("error2", errorform);
      return null;
    }else{
      if(type === "photo1") updateFields({errorform: "", photo1: file});
      if(type === "photo2") updateFields({errorform: "", photo2: file});
      if(type === "photo3") updateFields({errorform: "", photo3: file});
    }
  }

  const ToggleButton = () => {  
    const handleClick = () => {
      setIsToggled(!isToggled);
      updateFields({ acceptOffers: !isToggled });
    };  
    return (
      <button type="button"
        className={`w-20 h-10 flex rounded-full items-center px-1 ${
          isToggled ? 'bg-green-500' : 'bg-gray-500'
        }`}
        onClick={handleClick}
      >
        <span
          className={`transition duration-700 bg-white flex items-center justify-center w-8 h-8 rounded-full transition-transform ${
            isToggled ? 'transform translate-x-full' : ''
          }`}
        >{isToggled ? <svg xmlns="http://www.w3.org/2000/svg" width="20" zoomAndPan="magnify" viewBox="0 0 30 30.000001" className="pt-0.5" height="20" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="id1"><path d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 " clipRule="nonzero"></path></clipPath></defs><g clipPath="url(#id1)"><path fill="black" d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 " fillOpacity="1" fillRule="nonzero"></path></g></svg> :  
        
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"  className="pt-0.25 text-black" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path> </svg>}</span>
      </button>
    );
  };

  return (
    <>
      <div className="flex flex-col justify-center max-w-4xl">
        <label className="block mb-2 text-xl font-semibold text-gray-200">Price</label>
        <div className="flex pb-5 items-center justify-start py-2">
          <div className="flex items-center justify-center text-blue-300"> 
            <label className="block text-lg font-semibold text-white">$</label>
            <input type="text" name="reservePrice" id="reservePrice" className="bg-gray-900 text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ml-1 p-2.5"
            required placeholder="Price (USD)" onChange={e => updateFields({reservePrice: e.target.value})} value={reservePrice}/></div>
          <div className="ml-14 flex items-center justify-center">
            <label className="text-blue-50 text-lg">
              <input onChange={e => updateFields({hasFrame: !hasFrame})}  className="h-5 w-5 scale-125" type="checkbox" name="hasFrame"/> {`Frame${hasFrame ? "" : " not "} included`}
            </label>
          </div>
        </div>
        <div className="flex pb-3 mb-7 items-center border-b border-gray-700 w-full justify-center py-2">
          <label className="block text-base w-full font-semibold text-white">Accept offers</label>
          <ToggleButton />
        </div>
        <div className="mb-10 pb-3 border-b border-gray-700">
          <label className="block text-lg pb-3 w-full font-semibold text-white">Description</label>
          <TextField description={description} updateDescription={handleDescriptionUpdate} />
          <label className="block text-lg pb-4 w-full font-semibold text-white">Rarity</label>
          <select value={rarity} onChange={e => updateFields({rarity: e.target.value})} required className="bg-black mb-5 text-blue-50 h-7 max-w-xs focus:outline-none" name="rarity" id="rarity">
            <option value="Select a Classification">Select a Classification</option>
            <option value="Unique">Unique</option>
            <option value="Limited Edition">Limited Edition</option>
            <option value="Open Edition">Open Edition</option>
            <option value="Closed Edition">Closed Edition</option>
            <option value="Unknown Edition">Unknown Edition</option>
          </select>
        </div>
        <label className="block text-lg pb-3 w-full font-semibold text-white">Photos</label>
        <div className="block mb-2 text-md font-medium text-blue-300">Upload high quality photos of the work's front & back</div>
        <label className="block mb-2 text-lg font-medium text-green-300">
          Front of the artwork
        </label>
        <input type="file"
          onChange={({ target }) => {
            if (target.files) {
              handleUpload(target.files[0], "photo1");
            }
          }} 
          name="photo1" id="photo1" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          required
        />
        <label className="block mb-2 text-lg font-medium text-green-300">
          Back of the artwork
        </label>
        <input type="file"
          onChange={({ target }) => {
            if (target.files) {
              handleUpload(target.files[0], "photo2");
            }
          }} 
          name="photo2" id="photo2" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          required
        />
        <div className="text-center block mb-2 text-sm font-medium text-blue-300">If possible, include photos of signatures or certificates of authenticity</div>
        <label className="block mb-2 text-lg font-medium text-green-300">
          Signatures or certificates of authenticity
        </label>
        <input type="file"
          onChange={({ target }) => {
            if (target.files) {
              handleUpload(target.files[0], "photo3");
            }
          }} 
          name="photo3" id="photo3" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
        />
      </div> 
    </>
  )
}