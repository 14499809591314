import React, { useRef, useState } from "react";
import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import {Confetti, Loader} from "../../../components";
import Link from "next/link";
import { sendContactForm } from '../../../lib/api';
import { auth, database } from "../../../firebase/firebaseApp";
import { equalTo, get, set, query, ref, orderByChild } from 'firebase/database';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from 'next/router';
import { useAuthState } from "react-firebase-hooks/auth";

const AuthForm = ({source}) => {
  const [formLogin, setFormLogin] = useState(true);
  const [forgotPass, setForgotPass] = useState(false);
  const [messageSignUp, setMessageSignUp] = useState('');
  const [messageSignIn, setMessageSignIn] = useState('');
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  const [bigError, setBigError] = useState('');
  const result = useRef(false);

  const router = useRouter();
  const [user, loading] = useAuthState(auth);

  const handleBigError = async () =>  {
    const bigErrorMessage = `Something went wrong. We received the bug and will fix it immediately: ${bigError}`;
    const newVal =  { uid: "Big Error", name: "Monet", email: "you.setgoals@gmail.com", subject: "Bug / Error", message: bigErrorMessage};
    try {
      await sendContactForm(newVal);
    } catch (error) {
      error = error.message;
      console.log("Acess Error", error)
    }
  }

  function mapAuthCodeToMessage(authCode) {
    console.log(authCode);
    const error = authCode.split("(")[1].split(")")[0];
    switch (error) {
      // email errors
      case "auth/wrong-email":
        return "Email provided is invalid";
      case "auth/email-already-exists":
        return "Email already exists. Sign In or use a different email address"
      case "auth/invalid-email":
        return "Email provided is invalid";
      case "auth/email-already-in-use":
        return "Email is already registered. Try Signing in";
      case "auth/user-not-found":
        return "User does not exist. Email provided is invalid or Sign up";
      // password errors
      case "auth/weak-password":
        return "Password must be 6 characters or longer";
      case "auth/wrong-password":
        return "Wrong Password, please try again";
      case "auth/invalid-password":
        return "Wrong Password, please try again";
      // other errors
      case "auth/invalid-display-name":
        return "Name cannot be empty";
      case "auth/invalid-photo-url":
        return "Photo must be a string URL";
      case "auth/phone-number-already-exists":
        return "Phone number already exists. Sign in or contact support";
      // Many more authCode mapping here...
      default:
        setBigError(error)
        handleBigError();
        return `Something went wrong. We apologize for the inconvenince and will work to fix it immediately`;
    }
  }

  function writeUserData(userId, name, date_of_birth) {
    const messageID = uuidv4();
    const dateSent = String(new Date());
    const newMessage = {
      text: `Welcome to Monet, ${name}! We are thrilled to have you as a part of our creative community. Visit your profile page and make a post about your unique creative expression or your favorite artists. Engage, educate, and interact with other creatives to inspire and be inspired. Don't hesitate to reach out from our support page for any questions or concerns, as we are committed to ensuring you have the best experience on Monet. Let's create together!`,
      imageURL: "",
      uid: "cav1VwvsbHOVIVMO6pZ9nxGGqSC2",
      profileURL: "/Monet_logo.webp",
      messageID: messageID,
      createdAt: dateSent,
    };   
 
    set(ref(database, 'user_profile/' + userId), {
      username: name,
      mailing_list: true,
      profileURL: "/blank-profile-picture.webp",
      messages: {
        "cav1VwvsbHOVIVMO6pZ9nxGGqSC2": {
          unread: true,
          messageID: newMessage,
        }
      },
      dob: date_of_birth,
      job_position: "",
      bio: "",
      country: "",
      comments: {
        comment_id: {
          message: "",
          message_date: "",
        }
      },
      verifiedSeller: false,
      liked_posts: {
        post_id: {
          liked_date: "",
        },
      },
      shared_posts: {
        post_id: {
          shared_date: "",
          platform_shared: "",
        },
      },
    });
  }

  const handleUniqueUsername = (name) => {
    if(name.includes(' ')){
      setMessageSignUp("Username cannot contain spaces");
      return false;
    }
    const doesNameExist = query(ref(database, 'user_profile'), orderByChild("username"), equalTo(name.toLowerCase()));
    get(doesNameExist).then((snapshot) => {
      if(snapshot.val()){
        result.current = true;
      }
    });
    if(result.current){
      setMessageSignUp("Username is taken");
      console.log()
      return false;
    }
    return true;
  }

  const handleAgeRestriction = (user_dob) =>  {
    const date_day = new Date().getDay();
    const date_month = new Date().getMonth();
    const date_year = new Date().getFullYear();

    const user_day = user_dob[2];
    const user_month = user_dob[1];
    const user_year = user_dob[0];

    var user_age = date_year - user_year;
    var m = date_month - user_month;

    if (m < 0 || (m === 0 && date_day < user_day)) {
      user_age--;
    }

    var calc_till_age = 13 - user_age;
    if (user_age === 12) setMessageSignUp("You are not old enough, try again in a year");
    if (user_age < 12) setMessageSignUp(`You are not old enough, try again in ${calc_till_age} years`);
    if (user_age >=  13) return true;
    return false;
  }

  async function createSub(email, name){
    const data = {
      "email": email,
      "fields": {
        "name": name,
      },
      "groups": ["83949546356343892"],
    }
    await fetch('https://connect.mailerlite.com/api/subscribers', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${process.env.NEXT_PUBLIC_MAILERLITE_API}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(data)
    })
    .then(response => {
      // Handle the response
      console.log("res",response)
    })
    .catch(error => {
      // Handle the error
      console.log("error", error)
    });
  } 

  const handleSignUpSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData(e.target);
    const form_values = Object.fromEntries(formData);
    if (handleAgeRestriction(form_values.dob.split("-")) && handleUniqueUsername(form_values.name)) {
      createUserWithEmailAndPassword(auth, form_values.email, form_values.password).then((userCredential) => {
        // Signed in 
        createSub(form_values.email, form_values.name.toLowerCase()); // add to email list
        const user = userCredential.user;
        updateProfile(user, {displayName: form_values.name.toLowerCase(), photoURL : "/blank-profile-picture.webp"});
        writeUserData(user.uid, form_values.name.toLowerCase(), form_values.dob);
        router.replace(`/pricing`);
        setSignUpSuccess(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = mapAuthCodeToMessage(error.message);
        setMessageSignUp(errorMessage)
      });
    }
  }

  const handleSignInSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData(e.target);
    const form_values = Object.fromEntries(formData);
    signInWithEmailAndPassword(auth, form_values.email, form_values.password).then((userCredential) => {
      // Signed in 
      // console.log(userCredential)
      // router.push(`/Profile/${userCredential.user.uid}`);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = mapAuthCodeToMessage(error.message);

      setMessageSignIn(errorMessage)
      // ...
    });
  }

  const SignUpForm = () => {
    return (
      <div className="py-6 px-6 lg:px-8">
        <h3 className="mb-4 text-xl text-blue-100 font-bold">
          SignUp to share and sell art
        </h3> 
        <form className="space-y-6" action="#signUp" onSubmit={handleSignUpSubmit}>
        <div className="text-red-500 font-medium">
          {messageSignUp}
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-indigo-300">
            Username
          </label>
          <div className="flex items-center justify-center text-blue-300">@<input type="text" name="name" id="name" className="bg-gray-900
          text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ml-1 p-2.5"
          placeholder="JohnDoe"
          required /></div>
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-indigo-300">
            We need to verify you age and wish you a happy brithday 🥳
          </label>
          <input type="date" name="dob" id="dob" className="bg-gray-900
          text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="DD/MM/YYYY"
          required />
        </div>
        <div>
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-indigo-300">
            Your email
          </label>
          <input type="email" name="email" id="email" className="bg-gray-900
          text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="name@company.com"
          required />
        </div>
        <div>
          <label htmlFor="password" className="block mb-2 text-sm font-medium text-indigo-300">
            Your password
          </label>
          <input type="password" name="password" id="password" className="bg-gray-900
          text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="********"
          required />
        </div>
        <div className="flex flex-row m-2 items-center">
          <input type="checkbox" value="" id="remember" aria-label="accept-Terms" className=" w-4 h-4 bg-gray-50 rounded border border-gray-300
            focus:ring-3 focus:ring-blue-300" required
          />
          <label htmlFor="password" className="block mb-2 text-sm font-medium pl-3 pt-2 text-blue-100">
            I accept the <Link prefetch={false} href="/legal/terms-and-cond" className="text-blue-400 hover:underline">Terms and Conditions</Link> and <Link prefetch={false} className="text-blue-400 hover:underline" href="/legal/policy">Privacy Policy</Link>
          </label>
        </div>
        <button className="w-full text-gray-900 bg-gradient-to-r from-green-400 via-green-300 to-green-400 hover:from-green-400 hover:to-green-400
        focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-md px-5 py-2.5 text-center">
          Sign up for an account
        </button>
        <div className="text-sm font-medium text-gray-500">
          Already have an account?{" "}
          <a href="#" onClick={() => setFormLogin(false)} className="text-pink-500 text-base font-semibold hover:underline">
            Sign In
          </a>
        </div>
      </form>
    </div>
    );
  }

  const SignInForm = () => {
    return (
      <div className="py-6 px-6 lg:px-8 text-left">
        <h3 className="mb-4 text-xl pb- font-bold text-blue-200">
          SignIn and see what you've missed
        </h3> 
        <div className="text-red-500 font-medium pb-3">
          {messageSignIn}
        </div>
        <form className="space-y-6" action="#" onSubmit={handleSignInSubmit}>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-blue-300">
              Your email
            </label>
            <input type="email" name="email" id="email" className="bg-gray-900
            text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="name@company.com"
            required />
          </div>
          <div>
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-blue-300">
              Your password
            </label>
            <input type="password" name="password" id="password" className="bg-gray-900
            text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="********"
            required />
          </div>
          <div className="flex justify-between">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input type="checkbox" value="" id="remember" aria-label="remember" className=" w-4 h-4 bg-gray-50 rounded border border-gray-300
              focus:ring-3 focus:ring-blue-300"
                  />
              </div>
              <label htmlFor="remember" className="ml-2 text-sm font-medium text-blue-100">
                Remember me
              </label>
            </div>
            <button type="button" onClick={() => setForgotPass(true)} className="text-sm text-blue-300 text-opacity-75 hover:text-blue-400 hover:underline">
              Forgot Password?
            </button>
          </div>
          <button type="submit" className="w-full text-white bg-gradient-to-r from-blue-700 via-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-600
          focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center">
            Sign In to your account
          </button>
          <div className="text-sm font-medium text-gray-500">
            Don't have an account?{" "}
            <a href="#signUp" onClick={() => setFormLogin(true)} className="text-pink-500 text-base hover:underline">
              Sign Up
            </a>
          </div>
        </form>
      </div>
    )
  }

  const ForgotPass = () => {
    const forgotPass = (e) => {
      e.preventDefault();
      var formData = new FormData(e.target);
      const form_values = Object.fromEntries(formData);
      const auth = getAuth();
      // console.log("forgot", form_values.email, auth, emailRegex.test(form_values.email));
      sendPasswordResetEmail(auth, form_values.email)
        .then(() => {
          // Password reset email sent!
          // ..
          toast.success("Check your email to reset your password 😊", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });
    }
    return (
      <div className="py-6 px-6 lg:px-8 text-left">
        <div className="text-red-500 font-medium pb-3">
          {messageSignIn}
        </div>
        <button onClick={() => setForgotPass(false)} type="button" className="text-white pb-10 hover:underline">Go Back</button>
        <div type="submit" className="text-white text-2xl font-semibold pb-10 text-center">Reset Password</div>

        <form className="space-y-6" action="#" onSubmit={forgotPass}>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-blue-300">
              Your email
            </label>
            <input type="email" name="email" id="email" className="bg-gray-900
            text-blue-100 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="name@company.com"
            required />
          </div>
          <button type="submit" className="w-full text-white bg-gradient-to-r from-pink-500 via-blue-600 to-pink-500 hover:from-blue-600 hover:to-blue-600
          focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Send Reset Password Link
          </button>
        </form>
      </div>
    );
  }  
  
  const handlePage = () => {
    if(signUpSuccess){
      createSub(user.email, user.displayName); // add to email list
    }
    if(user){
        router.push(`/`);
      // return (
      //   <div className="py-6 px-6 pt-20 h-screen lg:px-8 text-left">
      //     <h2 className="text-white text-3xl p-5">Welcome {user.displayName} 👋</h2>
      //     <p className="text-blue-100 text-xl text-center py-7">We are excited to see what you create!</p>
      //     <div className="text-white text-center mx-2 my-10">What would you like to do next?</div>
      //     <div className="px-0 md:px-24 lg:px-36 space-x-5 w-full flex items-center justify-center text-center">
      //       <Link prefetch={false} href={`/Profile/${user.uid}`} className="w-2/5 max-w-sm text-black bg-gradient-to-r from-pink-500 via-pink-400 to-pink-500 font-semibold rounded-lg text-sm px-5 py-2.5 text-center">
      //         Make a Post
      //       </Link>
      //       <Link prefetch={false} href="/sell_art" className="w-2/6 bg-gradient-to-r from-green-300 to-green-500 font-bold rounded-lg text-sm px-5 py-2.5 text-black text-center">
      //         Sell Art
      //       </Link>
      //       <Link prefetch={false} href="/" className="w-3/5 text-white bg-gradient-to-r from-blue-500 to-blue-500 font-bold rounded-lg text-sm px-5 py-2.5 text-center">
      //         Find Inspiration
      //       </Link>
      //     </div>
      //   </div>
      // );
    }
    else if (forgotPass) return <ForgotPass />;
    else if(!user){
      if (forgotPass) return <ForgotPass />;
      else if (formLogin) return <SignUpForm />;
      else if (!formLogin) return <SignInForm />;
    }
    else return <div className="h-96 pt-56 pb-80"><Loader /></div>
  }

  return (
    <div>
      {signUpSuccess && <Confetti />}
      <div className="flex items-center justify-center w-full">
        <div className="w-11/12">{handlePage()}</div>
      </div>
    </div>
  );
};

export default AuthForm;