import React, { useRef, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { database, updateData } from "../../firebase/firebaseApp";
import { get, increment, ref } from "firebase/database";

const Collaborate = ({user, userID, artData}) => {
  const [collabMessage, setCollabMessage] = useState("");  
  // const [daysDiff, setDaysDiff] = useState(null);  
  // const [numreq, setNumreq] = useState(0);  

  function sendMessage(e){
    e.preventDefault();
    console.log("here", userID, user);
    var formData = new FormData(e.target);
    const form_values = Object.fromEntries(formData);
    const updates = {};
    const messageID = uuidv4();
    const dateSent = String(new Date());
    if(!user.uid){
      console.log("hereoooo");
      return toast.error("Sign in to continue", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }

    var daysDiff = null;  
    var numreq = 0; 
    const doesNameExist = ref(database, '/user_profile/' + user.uid + "/CollabReq/" + userID);
    get(doesNameExist).then((snapshot) => {
      if(snapshot.exists() && !artData){
        const days = snapshot.val().initalRequestDate;
        numreq = snapshot.val().requestCount;
        const lastClickedDate = new Date(days);
        const currentDate = new Date();
        const timeDiff = currentDate.getTime() - lastClickedDate.getTime();
        daysDiff = timeDiff / (1000 * 60 * 60 * 24);

        // Check if enough time has passed since last click
        if (daysDiff < 3 && numreq  >= 1) {
          return toast.error("Limit exceeded. Come back in 3 days or Upgrade your subscription", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }else if(daysDiff >= 3 || !daysDiff){
          updates['/user_profile/' + user.uid + "/CollabReq/" + userID ] = {
            initalRequestDate: dateSent,
            requestCount: increment(1),
          }
        }
      }
    });

    updates['/user_profile/' + user.uid + "/CollabReq/" + userID ] = {
      initalRequestDate: dateSent,
      requestCount: increment(1),
    }
    // you can only request to collaborate 3 times a week, upgrade to collab
    if(artData){
      console.log("userness", artData);
      const newMessage = {
        text: user.displayName + " message on your listing: "+ collabMessage,
        imageURL: artData.photo1,
        uid: user.uid,
        profileURL: user.photoURL,
        messageID: messageID,
        createdAt: dateSent,
      };   
      updates['/user_profile/' + userID + "/messages/" + user.uid + "/" + messageID ] = newMessage;
    }
    else if(form_values.collaborate){
      const newMessage = {
        text: user.displayName + " wants to collaborate: "+ collabMessage,
        imageURL: "",
        uid: user.uid,
        profileURL: user.photoURL,
        messageID: messageID,
        createdAt: dateSent,
      };   
      updates['/user_profile/' + userID + "/messages/" + user.uid + "/" + messageID ] = newMessage;
    }else{
      const newMessage = {
        text: form_values.commission + ": "+ collabMessage,
        imageURL: "",
        uid: user.uid,
        profileURL: user.photoURL,
        messageID: messageID,
        createdAt: dateSent,
      };   
      updates['/user_profile/' + userID + "/messages/" + user.uid + "/" + messageID ] = newMessage;
    }
    // Add data to firebase RealTime Database
    updateData(updates);
    setCollabMessage("");
    return toast.success("Message Sent! 😊", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  
    const [isChecked, setIsChecked] = useState({
      commission: false,
      collaborate: true,
    });
    const handleCheckboxChange = (name) => {
      setIsChecked({
        commission: name === 'commission' ? true : false,
        collaborate: name === 'collaborate' ? true : false,
      });
    };

  const CheckBox = () => {
    return (
      <div className="flex items-center justify-center w-full mb-10">
        <label className="inline-flex items-center">
          <input
            name="commission"
            value="commission"
            onChange={() => handleCheckboxChange('commission')}
            checked={isChecked.commission}
            type="checkbox"
            className="form-checkbox checked:bg-green-500 h-6 w-6 text-green-600 rounded transition duration-150 ease-in-out"
          />
          <span className="ml-2 text-gray-200">Commission</span>
        </label>
        <label className="inline-flex items-center ml-6">
          <input
            name="collaborate"
            value="collaborate"
            onChange={() => handleCheckboxChange('collaborate')}
            checked={isChecked.collaborate}
            type="checkbox"
            className="form-checkbox checked:bg-green-500 h-6 w-6 text-green-600 rounded transition duration-150 ease-in-out"
          />
          <span className="ml-2 text-gray-200">Collaborate</span>
        </label>
      </div>
    );
  }
  if(artData){
    return (
      <form className="h-96 w-full bg-gray-900" onSubmit={sendMessage}>
      <div className="w-full px-3 h-full flex flex-col items-center jusitfy-center">
        <div className="block pt-20 mb-2 text-md text-center font-medium text-green-400">We ensure that the items we review are authentic. All purchases made on Monet are elgible for proteection.</div>
        <label className="mx-5 block mb-2 py-5 text-sm text-center font-medium text-white">Send a message</label>
        <div className="flex items-center w-2/3 justify-center">
          <input
            required
            type="text"
            value={collabMessage}
            onChange={(e) => setCollabMessage(e.target.value)}
            placeholder="Send a message to request more details or discuss price"
            className="w-full border-b-2 border-green-400 mb-3 px-1 bg-gray-900 rounded py-2  text-green-400 placeholder-gray-400 shadow-md focus:outline-none focus:shadow-outline"
          />
          <button disabled={collabMessage === ""} type="submit" className="disabled:opacity-50 disabled:pointer-events-none text-xl font-semibold px-3 py-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="disabled:text-white text-green-400" viewBox="0 0 16 16"> <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"></path> </svg>
          </button>
        </div>
      </div>
    </form>
    )
  }
  return (
    <form className="h-96 w-full bg-gray-900" onSubmit={sendMessage}>
      <div className="w-full h-full flex flex-col items-center jusitfy-center">
        <label className="pt-14 mx-5 block mb-2 pb-5 text-sm text-center font-medium text-white">Request a commission or pitch your idea to start a new project. Respectful and professional communication is key to a successful collaboration.</label>
        <label className="block mb-2 pb-5 text-md text-center font-medium text-green-400">Happy creating!</label>
        <CheckBox />
        <div className="flex items-center w-2/3 justify-center">
          <input
            required
            type="text"
            value={collabMessage}
            onChange={(e) => setCollabMessage(e.target.value)}
            placeholder={isChecked.commission ? "Describe your commision request" : isChecked.collaborate ? "Describe your collaboration idea" : "Select a reason for your request"}
            className="w-full border-b-2 border-green-400 mb-3 px-1 bg-gray-900 rounded py-2  text-green-400 placeholder-gray-400 shadow-md focus:outline-none focus:shadow-outline"
          />
          <button disabled={collabMessage === ""} type="submit" className="disabled:opacity-50 disabled:pointer-events-none text-xl font-semibold px-3 py-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="disabled:text-white text-green-400" viewBox="0 0 16 16"> <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"></path> </svg>
          </button>
        </div>
      </div>
    </form>
  );
}

export default Collaborate;