type FormData = {
  businessCountry: string,
  businessType: string,
  BusinessName: string,
  terms: boolean,

  citizenship: string,
  birthCountry: string,
  identityProofType: boolean,
  countryOfIssue: string,
  identityNumber: string,
  expiryDate: Date,
  OTP: string,
  ZIP: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  number: string,
  SMS_Verification_Language: string,
}

type BusinessFormProps = FormData & {
  updateFields: (fields: Partial<FormData>) => void
}


export function BusinessForm({businessCountry, identityNumber, birthCountry, businessType, BusinessName, terms, citizenship, countryOfIssue, updateFields}: BusinessFormProps) {
  return (
    <>
      <div className="flex flex-col py-5">
        <label className="block mb-2 text-lg font-medium text-green-300">
          Business type
        </label>
        <select value={businessType} onChange={e => updateFields({businessType: e.target.value})} required className="bg-black text-green-200 h-7 max-w-sm focus:outline-none" name="businessType" id="businessType">
          <option value="Select a Business Type">Select a Business Type</option>
          <option value="None, I am an individual">None, I am an individual</option>
          <option value="State-owned business">State-owned business</option>
          <option value="Publicly-listed business">Publicly-listed business</option>
          <option value="Privately-owned business">Privately-owned business</option>
          <option value="Charity">Charity</option>
        </select>
      </div> 
      {businessType !== "None, I am an individual" && businessType !== "" &&
        <div>
          <div className="flex flex-col pb-5">
            <label className="block mb-2 text-lg font-medium text-green-300">
              Country where the Business is registered
            </label>
            <input autoFocus onChange={e => updateFields({businessCountry: e.target.value})} value={businessCountry} type="text" name="businessCountry" id="businessCountry" className="bg-black focus:outline-none border-b border-blue-300
              text-blue-100 text-sm block w-full p-2.5 placeholder:text-xs"
              placeholder="Business name as it appears on business registration document"
              required />
          </div> 
          <div className="flex flex-col pb-5">
            <label className="block mb-2 text-lg font-medium text-green-300">
              Business name, used to register with your state or federal government(If you're an individual enter NA)
            </label>
            <input autoFocus onChange={e => updateFields({BusinessName: e.target.value})} value={BusinessName} type="text" name="businessname" id="businessname" className="bg-black focus:outline-none border-b border-blue-300
              text-blue-100 text-sm block w-full p-2.5 placeholder:text-xs"
              placeholder="Business name as it appears on business registration document"
              required />
          </div> 
        </div>
      }
      <div>
        <label className="block mb-2 text-lg font-medium text-green-300">
          Country of citizenship
        </label>
        <input onChange={e => updateFields({citizenship: e.target.value})} value={citizenship} type="text" name="citizenship" id="citizenship" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          placeholder="Ex: United States" required
        />
        <label className="block mb-2 text-lg font-medium text-green-300">
          Birth Country
        </label>
        <input onChange={e => updateFields({birthCountry: e.target.value})} value={birthCountry} type="text" name="birthCountry" id="birthCountry" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          placeholder="Ex: United States" required
        />
        <label className="block mb-2 text-lg font-medium text-green-300">
          Passport/ID number
        </label>
        <input onChange={e => updateFields({identityNumber: e.target.value})} value={identityNumber} type="text" name="identityNumber" id="identityNumber" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          placeholder="Make sure all digits are entered correctly" required
        />
        <label className="block mb-2 text-lg font-medium text-green-300">
        Passport/ID country of issue
        </label>
        <input onChange={e => updateFields({countryOfIssue: e.target.value})} value={countryOfIssue} type="text" name="countryOfIssue" id="countryOfIssue" className="bg-black focus:outline-none border-b border-blue-300
          text-blue-100 text-sm block w-full mb-5 p-2.5 placeholder:text-xs"
          placeholder="Ex: United States" required
        />
      </div>
      <div>
        <div className="flex py-5 items-center">
          <input onChange={e => updateFields({terms: !terms})} value="" type="checkbox" id="terms" className="mt-1 w-10 h-10 bg-gray-50 rounded border border-gray-300" required
          />
          <label className="text-sm pl-3 font-medium text-gray-300">
            I confirm all the information is correct, and I understand that this information cannot be changed later
          </label>
        </div>
      </div>
    </>
  )
}