import { request, gql } from 'graphql-request';

const graphqlAPI = process.env.NEXT_PUBLIC_GRAPHCMS_ENDPOINT;

export const getPosts = async () => {
  const query = gql`
    query MyQuery {
      postsConnection(
        orderBy: createdAt_DESC
      ) {
        edges {
          cursor
          node {
            author {
              bio
              name
              id
            }
            id
            createdAt
            slug
            title
            excerpt
            reads
            featuredImage {
              url
            }
            altImage
            categories {
              name
              slug
            }
          }
        }
      }
    }
  `;

  const result = await request(graphqlAPI, query);

  return result.postsConnection.edges;
};

export const getCategories = async () => {
  const query = gql`
    query GetGategories {
        categories {
          name
          slug
        }
    }
  `;

  const result = await request(graphqlAPI, query);

  return result.categories;
};


export const getFirstPost = async (slug) => {
  const query = gql`
  query MyQuery {
    postsConnection(
      orderBy: createdAt_DESC
    ) {
      edges {
        cursor
        node {
          author {
            bio
            name
            id
          }
          id
          createdAt
          slug
          title
          excerpt
          reads
          featuredImage {
            url
          }
          altImage
          categories {
            name
            slug
          }
          content {
            json
          }
        }
      }
    }
  }
  `;

  const result = await request(graphqlAPI, query, { slug });

  return result.postsConnection.edges;
};

export const getPostDetails = async (slug) => {
  const query = gql`
    query GetPostDetails($slug : String!) {
      post(where: {slug: $slug}) {
        title
        excerpt
        featuredImage {
          url
        }
        altImage
        author{
          name
          bio
        }
        createdAt
        id
        slug
        reads
        content {
          json
        }
        categories {
          name
          slug
        }
      }
    }
  `;

  const result = await request(graphqlAPI, query, { slug });

  return result.post;
};

export const getSimilarPosts = async (categories, slug) => {
  const query = gql`
    query GetPostDetails($slug: String!, $categories: [String!]) {
      posts(
        where: {slug_not: $slug, AND: {categories_some: {slug_in: $categories}}}
        last: 3
      ) {
        title
        altImage
        featuredImage {
          url
        }
        createdAt
        id
        slug
      }
    }
  `;
  const result = await request(graphqlAPI, query, { slug, categories });

  return result.posts;
};

export const getCategoryPost = async (slug) => {
  const query = gql`
    query GetCategoryPost($slug: String!) {
      postsConnection(
        orderBy: createdAt_DESC
        where: {categories_some: {slug: $slug}}
      ) {
        edges {
          cursor
          node {
            author {
              bio
              name
              id
            }
            createdAt
            slug
            reads
            title
            id
            excerpt
            featuredImage {
              url
            }
            altImage
            categories {
              name
              slug
            }
          }
        }
      }
    }
  `;

  const result = await request(graphqlAPI, query, { slug });

  return result.postsConnection.edges;
};

export const getFeaturedPosts = async () => {
  const query = gql`
    query GetCategoryPost() {
      posts(
        orderBy: createdAt_DESC
        where: {featuredPost: true}
      ) {
        author {
          name
        }
        featuredImage {
          url
        }
        altImage
        id
        title
        slug
        createdAt
      }
    }   
  `;

  const result = await request(graphqlAPI, query);

  return result.posts;
};

export const submitComment = async (obj) => {
  const result = await fetch('/api/comments', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  });

  return result.json();
};

export const getComments = async (slug) => {
  const query = gql`
    query GetComments($slug:String!) {
      comments(
        orderBy: createdAt_DESC
        where: {post: {slug:$slug}}
      ){
        name
        createdAt
        comment
      }
    }
  `;

  const result = await request(graphqlAPI, query, { slug });

  return result.comments;
};

export const getRecentPosts = async () => {
  const query = gql`
    query GetPostDetails() {
      posts(
        orderBy: createdAt_DESC
        last: 3
      ) {
        title
        featuredImage {
          url
        }
        id
        altImage
        createdAt
        slug
      }
    }
  `;
  const result = await request(graphqlAPI, query);

  return result.posts;
};

export const getArt = async () => {
  const query = gql`
  query getArt {
    assets {
      id
      fileName
      url
      size
    }
  }
  `;

  const result = await request(graphqlAPI, query);
  return result.assets;
};

export const getPostDetailsFeed = async () => {
  const query = gql`
  query MyQuery {
     authorsConnection(
      orderBy: createdAt_DESC
    ) {
      edges {
        cursor
        node {
          updates(
            orderBy: createdAt_DESC
          ){
            createdAt
            id
            name
            slug
            isBlog
            author {
              bio
              name
              id
            }
            update {
              json
            }
          }
          posts(
            orderBy: createdAt_DESC
          ) {
            author {
              bio
              name
              id
            }
            id
            isBlog
            reads
            createdAt
            slug
            title
            content{
              json
            }
            excerpt
            featuredImage {
              url
            }
            altImage
            categories {
              name
              slug
            }
          }
          bio
          createdAt
          id
          name
        }
      }
    }
  }`;

const result = await request(graphqlAPI, query);

return result.authorsConnection.edges;
};