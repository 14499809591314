import React from 'react';
import Image from 'next/image';
import { updateData } from '../../firebase/firebaseApp';
import Head from 'next/head';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { v4 as uuidv4 } from 'uuid';

const NewPostDetail = ({ post }) => {
  function write(){
    const blogID =  "BLOG" + uuidv4() + post.author.name.toLowerCase().replace(/\s/g, "");
    const postUpdates = {};
      postUpdates['/blog/' + blogID] = postUpdates['/posts/' + blogID] = {  
        title: post.title,
        postExcerpt: post.excerpt,
        postText: JSON.stringify(post.content.json),
        postImageURL: post.featuredImage[0].url, 
        postAltImage: post.altImage,
        categories: post.categories,
        createdAt: post.createdAt, 
        postLikeCount: 0,
        reads: post.reads + 3,
        slug: post.slug,
        isBlog: true,
        profileURL: "https://firebasestorage.googleapis.com/v0/b/monet-a06c7.appspot.com/o/profilePic%2FLLfX2KcUvcZDFSepDRHnb81PU1u2?alt=media&token=716e0a53-1e0c-4c2b-bee3-3a7ed7a49eb0",
        uid: "LLfX2KcUvcZDFSepDRHnb81PU1u2",
        username: post.author.name.toLowerCase().replace(/\s/g, ""),
        userBio: post.author.bio,
        postID: blogID,
      };

      // Add data to firebase RealTime Database
      updateData(postUpdates);
  }

  return (
    <> 
      <Head>
        <title>{post.title}</title>
        <meta name="description" content={post.excerpt} key="desc" />
        <meta property="og:title" content={post.title} />
        <meta
          property="og:description"
          content={post.excerpt}
        />
        <meta
          property="og:image"
          content={post.featuredImage[0].url}
        />
      </Head>
      <div className="bg-white shadow-lg rounded-lg pb-12 pt-10 mb-8">
        {/* <button type="button" onClick={write} className="text-white border py-2 px-1 text-xl bg-red-500">Upload</button> */}
        <div className="relative overflow-hidden h-96 w-full shadow-md mb-6">
          <Image 
            unoptimized
            fill
            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
            src={post.featuredImage[0].url} 
            alt={post.altImage} 
            className="object-center h-96 w-full object-cover  shadow-lg rounded-t-lg" 
          />
        </div>
        <div className="sm:px-4 mx-4 lg:px-0">
          <div className="flex flex-row justify-center items-center">
            <div className="mb-10 w-8/12">
              <div className="flex sm:mr-8 items-center">
              <div className="relative w-14 h-14">
                <Image
                    unoptimized
                    alt={post.author.name}
                    fill
                    className="align-middle rounded-full h-10 w-10 sm:h-14 sm:w-14"
                    src="/juliano.webp"
                    sizes="(max-width: 768px) 100vw,
                (max-width: 1200px) 50vw,
                33vw"
                  />
              </div>
                <div className="inline text-gray-700 ml-2 font-medium text-lg">{post.author.name}</div>
              </div>
            </div>
          </div>
          <h1 className="mb-14 text-center text-3xl font-semibold">{post.title}</h1>
          <RichText
            content={post.content.json}
            renderers={{
              h1: ({ children }) => <h1 className="text-white mx-2">{children}</h1>,
              h3: ({children}) => <h3 className="text-3xl font-semibold mx-7 mt-20 mb-10">{children}</h3>,
              h4: ({children}) => <h4 className="text-2xl text-center font-semibold mt-20 mb-10">{children}</h4>,     
              p: ({children}) => <div className="mb-5 mx-7 text-lg">{children}</div>,
              ol: ({children}) => <div className="mb-8 mx-7 text-lg">{children}</div>,
              blockquote: ({children}) => <div className="text-xs text-center font-base mb-7">{children}</div>,
              bold: ({ children }) => <div className="inline block py-4 font-bold">{children}</div>,
              a: ({href, children}) => { 
                return (<a className="text-blue-600 font-semibold" href={href}>{children}</a>)
              },
              img: ({src, width, height, title}) => <div className="flex mt-14 items-center justify-center"><Image unoptimized width={width} height={height} className="mb-3" src={src} alt={title}/></div>,
              code: ({ children }) => {
                const html = children;
                return (
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                );
              }
            }}
          /> 
        </div>
      </div> 
    </>
  );
};

export default NewPostDetail;
