        
import {
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  PinterestShareButton,
  PinterestIcon,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  FacebookIcon,
  FacebookShareButton,
  EmailShareButton,
  EmailIcon,
} from 'next-share';
import { ref, set, push} from "firebase/database";
import { database } from '../firebase/firebaseApp';

const Share = ({uid, url, post, isBlog}) => {     
  function UpdateDatabase(platform){
    function writeUserData(uid, postID, platform) {
      if(platform !== "" && postID != "") {
        const newShare = push(ref(database, `/user_profile/${uid}/shared_posts`));
        set(newShare,
          {
            "platform_shared":  platform,
            "shared_date" : new Date().toLocaleDateString(),
            "post_shared_id": postID
          });
      }
    }
    if(uid !== "" && uid !== undefined){
      var postID = "";
      if(isBlog){
        postID = post.id;
      }else{
        postID = post.postID
      }
      writeUserData(uid, postID, platform);
    }
  }


  var shareColor = "text-blue-50"
  if(isBlog){
    shareColor = "text-gray-900";
  }
  
  return (
    <div className="px-5 py-5">
      <p className={`pb-4 font-semibold text-base text-center ${shareColor}`}>Share the creativity</p>
      <div className="flex flex-row space-x-1 pl-3">
        <div onClick={() => UpdateDatabase("Linkedin")}>
          <LinkedinShareButton url={`${url}${post.slug}`}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
        <div onClick={() => UpdateDatabase("Reddit")}>
          <RedditShareButton  url={`${url}${post.slug}`} title={post.title}>
            <RedditIcon size={32} round />
          </RedditShareButton>
        </div>
        <div onClick={() => UpdateDatabase("Facebook")}>
          <FacebookShareButton url={`${url}${post.slug}`} hashtag="Monet Art">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>
        <div onClick={() => UpdateDatabase("Twitter")}>
          <TwitterShareButton url={`${url}${post.slug}`} title={post.title} via="Monet_LLC" hashtags={post.categories.map((cat) => cat.name)}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>
        <div onClick={() => UpdateDatabase("Pinterest")}>
          <PinterestShareButton url={`${url}${post.slug}`} media={post.postImageURL}>
            <PinterestIcon size={32} round />
          </PinterestShareButton>
        </div>
        <div onClick={() => UpdateDatabase("Whastapp")}>
          <WhatsappShareButton title={post.title} url={`${url}${post.slug}`}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton >
        </div>
        <div onClick={() => UpdateDatabase("Email")}>
          <EmailShareButton url={`https://${url}${post.slug}`} subject={post.title} body={post.excerpt}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
      </div>
    </div>
  );
}

export default Share;