import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify'
import { sendContactForm } from '../lib/api';

const SupportReq = ({ user, placeholderForum}) => {
  const [success, setSuccess] = useState(true);
  // set limit to 3 support request per day per user
  var submitCount = 0;
  // variable to store request information
  const initValues = { uid: "", name: "", email: "", subject: "", message: "" };
  const [formData, setFormData] = useState({ comment: ''});

  const initState = { values: initValues };
  const [state, setState] = useState(initState);
  const { values, isLoading } = state;
  const reference = useRef(values);

  const onInputChange = (e) => {
    const { target } = e;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handlePostSubmission = async () =>  {
    const { comment } = formData;
    ++submitCount;
    if (!user) {
      return toast.error("Sign in to continue", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (submitCount > 3) {
      return toast.error("Too many requests ⛔ try again later", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (comment.length < 5) {
      return toast.error("Message is too short 🤔", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }

    setSuccess(false);
    const newVal =  { uid: user.uid, name: user.displayName, email: user.email, subject: placeholderForum, message: comment};
    reference.current = newVal;

    try {
      await sendContactForm(reference.current);
      toast.success("Message Received 😊", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSuccess(true);
      setState(initState);
    } catch (error) {
      setState((prev) => ({
        ...prev,
        isLoading: false,
        error: error.message,
      }));
      console.error("error from support:", error)
    }
  };


  return (
    <div className="sm:px-10 sm:pt-10 flex flex-col items-center">
      <textarea required  name="subject" id="subject" className="w-5/6 sm:w-5/6 md:w-2/3 border-b border-blue-200 bg-transparent text-blue-400 text-2xl sm:text-2xl md:text-3xl lg:text-3xl font-semibold h-16 justify-center" type="text" placeholder="Select subject from above" rows="10" readOnly value={placeholderForum}/>
      <textarea required name="comment" value={formData.comment} onChange={onInputChange} id="content" className="w-5/6 sm:w-5/6 md:w-2/3 transition duration-900 py-3 bg-transparent text-white" type="text" placeholder="Describe in detail your request" rows="10"/>
      <button onClick={handlePostSubmission} className="mt-5 transition duration-500 text-white text-lg border px-2 font-medium rounded-sm hover:text-blue-400 hover:border-blue-400">{success ? <p>Submit</p> : <p className="animate-pulse text-blue-400">Loading</p>}</button>
    </div>
  );
};

export default SupportReq;
