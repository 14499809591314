import React from 'react';
import dynamic from 'next/dynamic';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebaseApp';

const Header = dynamic(() => import('./Header/Header'), {
  loading: () => <p>Loading...</p>,
  ssr: false,
  priority: true
});

const Legal = dynamic(() => import('./Legal/Legal'), {
  loading: () => <p>Loading...</p>,
  ssr: false,
  priority: false
});

const Layout = ({ provider, children }) => {
  const user = useAuthState(auth);

  return(
    <>
      <Header user={user[0]} auth={auth} provider={provider}/>
      {children}
      <div className="pt-20 bg-black"><Legal /></div>
    </>
  );
};

export default Layout;
