import React from 'react';
import Link from 'next/link';

const TermsText = () => {
  const privacyPolicy = () => <Link prefetch={false} className="text-blue-400" href="/legal/policy">Privacy Policy </Link>
  return (
    <div>
      <div className="pt-10">
        <h1 className="text-4xl md:text-8xl lg:text-9xl sm:text-8xl m-10 pt-10 text-white">Terms and Conditions</h1>
        <p className="m-10 pt-10 text-white text-xs">These Terms of Use were published on and last updated on July 11, 2023</p>
        <h2 className="text-xl m-10 pt-10 text-white">General</h2>
        <div className="m-10 text-white">Please read these terms of use ("Terms of Use") carefully. By accessing our 
        websites at [www.theMonet.co, www.Monet.io], including any subdomain thereof (the "websites"), clicking
        on the "Create Account" button, or by accessing or using the platform operated by Monet and its affiliates 
        (collectively the "Company", "Monet", "we" or "us") (the "Monet Platform") and the services or products 
        offered therein (the "Services"), you acknowledge and agree that you have read, understand and agree to be 
        legally bound by these Terms of Use, and all terms incorporated by reference, as well as our {privacyPolicy()}
          regardless of whether you are a registered member or registered user of the Monet Platform. If you do not 
        wish to continue to be bound by these Terms of Use, please stop using the Monet Platform, the websites, or 
        the Services immediately.</div>
        <h2 className="text-xl m-10 pt-10 text-white">Other Agreements</h2>
        <p className="m-10 text-white">In addition to these Terms of Use, you may enter into additional agreements with us that will govern your use of the Monet Platform, or any related services or products offered by us or others.</p>
        <p className="m-10 text-white">Please note that the terms and conditions and seller&rsquo;s conditions of sale should be read in conjunction with <Link prefetch={false} className="text-blue-400" href="/legal/conditions-of-sale">Monet&rsquo;s Conditions of Sale</Link>.</p>
        <p className="m-10 text-white">For example, in respect of any digital fractionalized units in artworks made available on the Monet Platform, there will be a separate set of agreements that may include documents such as (1) Terms and Conditions (2) Subscription 
        Agreement (3) Shareholders Agreement, and others which will be made available in respect of each artwork (the "Artwork Agreements"). The Artwork Agreements set out the terms that relate to such artworks, and the Terms of Use will continue to apply.</p>
        <p className="m-10 text-white">If there is a contradiction between these Terms of Use and any other agreement you enter into with us in respect of specific aspects of the Monet Platform or the services or products offered therein (e.g. the Artwork Agreements),
        the other agreement shall take precedence over such specific aspects of the Monet Platform or the services or products offered therein to which it applies.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Changes to Terms of Use</h2>
        <p className="m-10 text-white">Amendments may be made to the Terms of Use from time to time, and posted on the 
        websites, with an indication of the date on which the Terms of Use were last revised. You acknowledge, understand, 
        and agree that the continued use of the Monet Platform and the Services or websites after we have made any such 
        changes constitutes your acceptance of the new Terms of Use.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Eligibility</h2>
        <p className="m-10 text-white">Monet may restrict or limit the use of the Monet Platform or the Services in certain jurisdictions
        ("Restricted Jurisdictions") 1.You represent and warrant that you: (1) (if you are an individual) have legal capacity and are of 
        legal age to form a binding contract and that you are at least 18 years old, (2) have not previously been rejected for an account 
        or removed/suspended/restricted from using the Monet Platform or the Services, (3) are of sound mind and are capable of taking 
        responsibility for your own actions and have full power and authority to enter into this agreement, (4) are not violating any other 
        agreement to which you are a party in entering in this agreement, (5) you are not a resident or citizen of any Restricted Jurisdiction, 
        (6) all the information you provide to us are accurate, up to date and not misleading and that you will notify us of any changes to any
          information you have provided (7) any money that you use through the Monet Platform or the Services do not originate from drug trafficking,
          abduction, terrorist activity or any other criminal activity that is unlawful or could be considered unlawful by any relevant jurisdiction,
            (8) will not use the Monet Platform or the Services if any applicable laws in your country prohibit you from doing so and that your use of 
            the Monet Platform or the Services do not violate any laws or regulations of any jurisdiction that applies to you.</p>
        <p className="m-10 text-white">In addition, if you are acting on behalf of a legal entity, you further represent and warrant that (1) such 
        legal entity is duly incorporated and validly existing under the applicable laws of the jurisdiction of its incorporation, and (2) you are 
        duly authorized by such legal entity to act on its behalf..</p>
        <h2 className="text-xl m-10 pt-10 text-white">Registrations</h2>
        <p className="m-10 text-white">You agree to provide us with the information we request for the purposes of identity verification, know-your-customer (KYC) and detection of money laundering, terrorist financing, fraud, or any other financial crime and permit us to keep a record of such information. You will need to complete certain verification procedures before you are able to use certain Services on the Monet Platform, and in some cases, extra verification or information may be required. Limitations may be applied to your use of the Services, and these limitations on use may be altered as a result of information collected from you on an ongoing basis.</p>
        <p className="m-10 text-white">The information that we request may include certain personal information, including but not limited to, your name, address, telephone number, e-mail address, date of birth, residency, citizenship, identification number, information regarding your bank account, and any other personally identifiable information that we may ask for from time to time such as a copy of your passport, proof of addresses or other identifying documentation. In providing such information or other information that may be required, you confirm that the information is accurate and authentic. You agree to update us of any changes in the information you have provided. We may require you to submit additional information about yourself and your business, and provide extra records or confirmations.</p>
        <p className="m-10 text-white">We reserve the right to limit your use of the Platform and the Services or terminate any relationship with you depending on the results of such due diligence. If you do not provide such information within the time frame we require, or provide inaccurate, incomplete, or misleading information, we reserve the right to limit, block access or terminate any relationship with you.
        You authorize us to make inquiries, whether directly or through third parties, that we consider necessary to verify your identity or to protect you and us against fraud or financial crime, and to take action we reasonably deem necessary based on the results of inquiries. When we carry out these inquiries, you acknowledge and agree that your personal information may be disclosed to reference and fraud prevention or financial crime agencies and that these agencies may respond to our inquiries in full.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Privacy & Data Protection</h2>
        <div className="m-10 text-white">You acknowledge that we may process personal data in relation to you (if you are an individual), and personal data that you have provided 
        or in the future provide to us in relation to your employees or other associated individuals, in connection with the Terms of Use (or such other agreements). You represent 
        and warrant that: (1) your disclosure to us of any personal data relating to individuals other than yourself was or will be made in accordance with all applicable data protection 
        and data privacy laws, and those data are accurate, up to date and relevant when disclosed; (2) before providing any personal data to us, you have read and understood our {privacyPolicy()} 
        or have provided such Privacy Policy to such individual other than yourself; (3) from time to time when provided with a replacement version of the Privacy Policy, you will promptly read such 
        notice and provide a copy to the individual whose data you have provided to us.</div>
        <h2 className="text-xl m-10 pt-10 text-white">Electronic Signatures and Agreement</h2>
        <p className="m-10 text-white">You acknowledge and agree that by clicking the "I agree" or similar buttons or links as may be designated by Monet to show your approval of any foregoing texts, 
        the use of the Monet Platform, or the Services, you are entering into a legally binding contract. You hereby agree to the use of electronic communication to enter into contracts and other records
        and to the electronic delivery of notices, policies, records of use, or transactions initiated or completed through our websites and Monet Platform. Furthermore, you waive any rights or requirements
          under laws or regulations in any jurisdictions which require an original (non-electronic) signature or delivery or retention of non-electronic records, to the extent permitted under such applicable law.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Electronic Notices</h2>
        <p className="m-10 text-white">You consent to electronically receive all notices, communications, agreements, documents, and disclosures ("Notices") that we provide you as a user of the Monet Platform and the Services. We will provide these Notices by posting them on the websites or by sending them to the email address that you have provided us, or through instant messaging chats and/or other electronic communication.</p>
        {/* <h2 className="text-xl m-10 pt-10 text-white">Trademarks</h2>
        <p className="m-10 text-white">"Monet" and other graphics, logos, designs, page headers, buttons, scripts, and service names are registered trademarks, trademarks, or trade property of Monet in several countries. Monet’s trademarks may not be used in whole or in part without the prior written consent of Monet.</p> */}
        <h2 className="text-xl m-10 pt-10 text-white">Limited License</h2>
        <p className="m-10 text-white">Monet grants you a limited, non-exclusive, non-transferable license, subject to these Terms of Use, to access and use the Monet Platform, the Services, and the websites (the "Content") solely for approved purposes as permitted by us from time to time. All other use of the Content is expressly prohibited and all other right, title, and interest in the Content is exclusively the property of Monet. You agree not to copy, transmit, distribute, sell, license, reverse engineer, modify, publish or participate in the transfer or sale of, creative derivative works from, or in any other way use any of the Content in whole or in part. You may not copy, imitate, or use any of the Content without our prior consent. No part of the Content may be reproduced in any manner, in whole or in part, without the written consent of Monet.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Disclaimers</h2>
        <p className="m-10 text-white">Monet does not provide any financial advice. None of the information that Monet provides on the Monet Platform or the websites should be regarded as "investment advice" or "recommendation" regarding a course of action, including without limitation, as those terms are used in any applicable law or regulations. Information provided on the Monet Platform or the websites is provided with the understanding that (1) Monet is not acting in a fiduciary or advisory capacity under any contract with you, or any applicable law or regulation, (2) you will make your own independent decision with respect to any course of action in connection herewith as to whether such course of action is appropriate or proper based on your own judgment and your specific circumstances and objectives, (3) you are capable of understanding and assessing the merits of a course of action and evaluating investment risks independently.</p>
        <p className="m-10 text-white">Monet does not purport to and does not, in any fashion provide tax, accounting, actuarial, record keeping, legal, broker/dealer, or any related services. You should consult your own advisors with respect to these areas and any material with regard to investment decisions. You may not rely on the material contained herein. Monet shall not have any liability for any damages of any kind whatsoever relating to this material.</p>
        <p className="m-10 text-white">Monet is not a financial institution and is not licensed by or under the supervision of any financial supervisory authority and does not provide any licensed financial services such as investment services, capital raising, fund management, management of a collective investment scheme, or investment advice.</p>
        <p className="m-10 text-white">The website is provided "as is" and on an "as available" basis and we give no warranty that it will be free from defects and/or faults. We make no warranty or representation (express or implied) that it will be fit for any particular purposes, that it will not infringe the rights of third parties, that it will be compatible with all systems, that it will be secure or that all information provided will be continuous, uninterrupted, timely, accurate or error-free. Monet reserves the right to change any and all content on the Monet Platform and on the websites and any Services offered at any time without notice. Reference to any products, services, processes or other information by trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship, or recommendation thereof, or any affiliation with Monet (unless so expressly stated to be so).</p>
        <p className="m-10 text-white">The Monet Platform and the websites may be temporarily unavailable from time to time for maintenance or other reasons. We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction, or unauthorized access to, or alteration of your communications.</p>
        <h2 classNgit ame="text-xl m-10 pt-10 text-white">Limitation of Liability</h2>
        <p className="m-10 text-white">Monet accepts no liability for any direct or indirect loss or damage, whether or not foreseeable, including any indirect, consequential, or other damages arising from your use of the Monet Platform, the Services, or the websites or any information contained in it, to the maximum extent permissible by law.</p>
        <p className="m-10 text-white">You use the Monet Platform, the Services, and the websites at your own risk.
        We accept no liability for any disruption or non-availability of the Monet Platform or the websites or the Services or any damage or interruptions resulting from external causes including, but not limited to, communications network failure, power failure, host equipment failure, IPS (internet service provider) equipment failure, traffic congestion on the Internet or the website or a combination thereof, computer viruses, spyware, scareware or other malware that may affect your computer or other equipment, natural events, acts of war, legal restrictions and censorship.
        Monet accepts no liability in respect of any loss or damage resulting from any use of the Monet Platform, the websites or the Services, or any content posted by third parties on or through the websites, or any interactions between users of the Monet Platform, the websites or the Services, whether online or offline.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Indemnity</h2>
        <p className="m-10 text-white">You agree to indemnify Monet, our affiliates and service providers, and each of our and their respective officers, directors, agents, employees, and representatives, in respect of any costs (including legal fees and any fines, fees, or penalties imposed by any regulatory or governmental authority) that have been incurred in connection with any claims, demands or damages arising out of or related to your breach and/or our enforcement of these Terms of Use (and any other agreements entered with you) or your violation of any law, rule, regulation or rights of any third party.</p>
        <h2 className="text-xl m-10 pt-10 text-white">No Waiver</h2>
        <p className="m-10 text-white">The failure of Monet to exercise or enforce any right, remedy, or provision of these Terms of Use does not constitute a waiver of such right, remedy, or provision and shall not be construed as a waiver.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Severability</h2>
        <p className="m-10 text-white">If any provision of these Terms of Use (or other agreement) is found to be unlawful, invalid, or otherwise unenforceable, that provision is deemed to be severed from the Terms of Use (or other agreement) and shall not affect the validity and enforceability of the remaining Terms of Use (or other agreement) which shall continue in full force and effect.</p>
        <h2 className="text-xl m-10 pt-10 text-white">Governing Law and Dispute Resolution</h2>
        <p className="m-10 text-white">These Terms of Use shall be governed by and construed and interpreted in accordance with the laws of Delaware. In addition, you hereby submit to the jurisdiction of the courts of Delaware.</p>
        <p className="m-10 text-white pl-7">"1.     Restricted Jurisdictions" will be determined from time to time by Monet, in accordance with any applicable sanctions regulations or selling restrictions, which is necessary for Monet to be in compliance with relevant laws and regulations. This list (as of the date of these Terms and Conditions) includes the Democratic People’s Republic of Korea (DPRK), Iran, Cuba, Albania, Serbia, Sudan, or Syria (or any other countries which are subject to applicable government sanctions).   </p>
        <h3 className="text-xl m-10 pt-10 text-white">Notice Re: European Economic Area</h3>
        <p className="m-10 text-white">As we are a global company, we may collect Personal Data from individuals and institutions located within the European Economic Area (“EEA”) or the Channel Islands. We are required to protect Personal Data processed in the EEA and the Channel Islands in accordance with the General Data Protection Regulation (“GDPR”) and equivalent local legislation. To understand more about how we protect the data we collect from individuals and institutions located within the EEA or the Channel Islands, please see the ‘Privacy Statement for Data Subjects Whose Personal Data May Be Collected in or from the EEA or the Channel Islands’ section below.</p>
      </div>
    </div>
  );
}

export default TermsText